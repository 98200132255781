import { addMinutes, format, isValid, parseISO } from 'date-fns';

export const dateDateFormat = 'dd LLL yyyy - HH:mm';
export const defaultDateFormatWithTz = 'dd LLL yyyy - HH:mm O';

export function parseServerDate(pricing: string): Date {
  return parseISO(pricing.replace(/\[.*]$/, ''));
}

export function formatDateUtc(serverDate: string): string {
  const date = parseServerDate(serverDate);
  if (!isValid(date)) {
    return 'Invalid';
  }
  const utcDate = addMinutes(date, date.getTimezoneOffset());
  return format(utcDate, dateDateFormat);
}

export function formatServerDate(serverDate: string): string {
  const date = parseServerDate(serverDate);
  if (!isValid(date)) {
    return 'Invalid';
  }
  return format(date, defaultDateFormatWithTz);
}

export function formatDate(serverDate: string): string {
  const date = parseISO(serverDate);
  if (!isValid(date)) {
    return '';
  }
  return format(date, 'dd LLL yyyy');
}
