import type { ColDef, ICellEditorParams, IRichCellEditorParams } from '@ag-grid-community/core';
import { assertUnreachable } from '@sgme/fp';
import { asSequence } from 'sequency';

import { isEntityType } from '@/store/api/rulesApi/rulesModels.ts';
import type {
  AnalyticalStructure,
  AnalyticalStructureRowData,
} from '@/store/slices/queryCache/queryCacheSlice.ts';
import type { RuleRowData } from '@/components/rules/rowDataManager/BaseRowDataManager.ts';

export function getDropdown(values: readonly (string | null)[]): ColDef {
  return {
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      // @ts-ignore
      values: values,
      searchType: 'match',
      allowTyping: true,
      filterList: true,
      highlightMatch: true,
      valueListMaxHeight: 450,
    } satisfies IRichCellEditorParams,
  };
}
export function getEntityNameDropdown(analyticalStructures: AnalyticalStructureRowData[]): ColDef {
  return {
    cellEditor: 'agRichSelectCellEditor',

    cellEditorParams: (params: ICellEditorParams<RuleRowData>) => {
      const values = getEntityNamesDropdownValues(analyticalStructures, params.data);
      return {
        values,

        searchType: 'match',
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
        valueListMaxHeight: 450,
      } satisfies IRichCellEditorParams;
    },
  };
}
function getEntityNamesDropdownValues(
  analyticalStructures: AnalyticalStructureRowData[],
  ruleRowData: RuleRowData,
): string[] {
  const entityType = ruleRowData.entityType;
  const analyticalStructureKey = getAnalyticalStructureKeyByEntityType(entityType);
  if (entityType == null || analyticalStructureKey === undefined) {
    return [];
  }
  const seqAnalyticalStructures = asSequence(analyticalStructures);
  return seqAnalyticalStructures
    .map(element => element[analyticalStructureKey])
    .distinct()
    .toArray();
}
function getAnalyticalStructureKeyByEntityType(
  entityType: unknown,
): keyof AnalyticalStructure | undefined {
  if (!isEntityType(entityType)) {
    return undefined;
  }

  switch (entityType) {
    case 'eliot_center':
      return 'GOP';
    case 'profit_center':
      return 'ProfitCenter';
    case 'eliot_portfolio':
      return 'PortFolio';
    default:
      assertUnreachable(entityType, `Unknown entity type ${entityType}`);
  }
}
