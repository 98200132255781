import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { asSequence } from 'sequency';

import { presetKeyToString, type PresetKey } from '@/store/slices/prefs/presetKey.ts';
import { Alert } from '@/components/common/bootstrap/Alert.tsx';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { Checkbox } from '@/components/common/bootstrap/Checkbox.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import type { IntlMessages } from '@/types/intl';
import { removeOneFromArray } from '@/utils/arrays.ts';

type ManagePresetModalProps = {
  onDeletePreset: (team: string, presetNames: string[]) => void;
  presetKeys: PresetKey[];
  defaultPresetKey: PresetKey;
  title: string;
  titleId: IntlMessages;
};

export const ManagePresetModal = NiceModal.create<ManagePresetModalProps>(
  ({ onDeletePreset, presetKeys, defaultPresetKey, title, titleId }) => {
    const presetsByTeam = asSequence(presetKeys)
      .filterNot(p => p.team === defaultPresetKey.team)
      .groupBy(p => p.team);

    const teams = [...presetsByTeam.keys()];

    const [selectedTeam, setSelectedTeam] = useState(teams[0]);
    const [presetNamesToDelete, setPresetNamesToDelete] = useState<string[]>([]);

    if (teams.length === 0) {
      return (
        <BootstrapModal titleId={titleId}>
          <Alert>No preset in your current teams</Alert>
        </BootstrapModal>
      );
    }

    const onDeleteClick = () => {
      onDeletePreset(selectedTeam, presetNamesToDelete);
    };

    function onCheckboxChange(presetName: string) {
      if (presetNamesToDelete.includes(presetName)) {
        setPresetNamesToDelete(
          removeOneFromArray(presetNamesToDelete, name => name === presetName),
        );
      } else {
        setPresetNamesToDelete(presetNamesToDelete.concat(presetName));
      }
    }

    return (
      <BootstrapModal
        titleId={titleId}
        footer={
          <CancelConfirmFooter
            onConfirm={onDeleteClick}
            confirmButtonProps={{
              variant: 'danger',
              disabled: presetNamesToDelete.length === 0,
              component: 'Delete selected',
            }}
          />
        }
      >
        <>
          <div className="form-group-deprecated">
            <label htmlFor="team" className="text-secondary form-label">
              Team
            </label>
            <Select
              id="team"
              itemsAsObjects={[...presetsByTeam.keys()]}
              selectedItem={selectedTeam}
              onChange={setSelectedTeam}
            />
          </div>
          <div className="form-group-deprecated">
            <label>{title}</label>
            {presetsByTeam.get(selectedTeam)!.map(presetKey => {
              return (
                <div
                  key={presetKeyToString(presetKey)}
                  className="d-flex border p-2 mt-1 "
                >
                  <Checkbox
                    checked={presetNamesToDelete.includes(presetKey.name)}
                    label={presetKey.name}
                    onChange={() => onCheckboxChange(presetKey.name)}
                  ></Checkbox>
                </div>
              );
            })}
            <Alert>Attention: presets will be deleted for the entire team</Alert>
          </div>
        </>
      </BootstrapModal>
    );
  },
);
