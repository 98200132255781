import { Link, Outlet, useNavigate } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { MessageTooltip } from '@/components/common/bootstrap/MessageTooltip.tsx';
import { isProdEnv } from '@/utils/config/configUtils.ts';
import { getSgwtHelpCenter } from '@/utils/sgwt/help-center.ts';

function openTeamTopic() {
  const topicId = isProdEnv() ? 'top.7wtnmqgg' : 'top.9p3evavr';
  const widget = getSgwtHelpCenter();
  widget.topic(topicId);
}

export function ManageScenariosPage(): JSX.Element {
  const navigate = useNavigate();
  const hasTeams = useAppSelector(userSlice.selectors.hasAnyTeam);

  return (
    <div className="d-flex flex-column h-100 pt-2">
      <div className="d-flex mx-auto w-75 justify-content-between">
        <div className="display-3">
          <FormattedMessage id="Hedger.ScenariosModal.Title" />
        </div>
        <div className="d-flex gap-2">
          <MessageTooltip messageId="Hedger.Scenarios.RequestAccess">
            <Button variant="warning" outline size="lg" hidden={hasTeams} onClick={openTeamTopic}>
              <div className="d-flex   align-items-center gap-2">
                <em className="icon icon-md">warning</em>
                Request access
              </div>
            </Button>
          </MessageTooltip>

          <Button
            size="lg"
            onClick={() => navigate({ to: '/hedger/scenarios/create' })}
            disabled={!hasTeams}
          >
            <FormattedMessage id="Hedger.ScenariosModal.CreateScenario" />
          </Button>
          <Button
            size="lg"
            onClick={() => navigate({ to: '/hedger/recipients/create' })}
            disabled={!hasTeams}
          >
            <FormattedMessage id="Hedger.ScenariosModal.CreateRecipient" />
          </Button>
        </div>
      </div>

      <ul className="d-flex mx-auto w-75 nav nav-underline nav-lg nav-hover pt-4">
        <li className="nav-item">
          <Link
            className="nav-link cursor-pointer"
            activeProps={{ className: 'active' }}
            to="/hedger/scenarios"
          >
            Scenarios
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link cursor-pointer"
            activeProps={{ className: 'active' }}
            to="/hedger/recipients"
          >
            Recipients
          </Link>
        </li>
      </ul>
      <span className="border-bottom mb-3" />
      <Outlet />
    </div>
  );
}
