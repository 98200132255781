import { useMemo } from 'react';
import type { ColDef, RowNode } from '@ag-grid-community/core';
import { useModal } from '@ebay/nice-modal-react';
import { useLoaderData } from '@tanstack/react-router';

import {
  useDeletePreTradeDealMutation,
  useEditPreTradeDealMutation,
} from '@/store/api/adjustmentsApi/adjustmentApi.ts';
import type { BlotterDeal, PreTradeDeal } from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { useAppDispatch } from '@/store/hooks.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { AdjustmentsGrid } from '@/components/adjustments/AdjustmentGrid.tsx';
import { ConfirmDeleteAdjustmentModal } from '@/components/adjustments/ConfirmDeleteAdjustmentModal';
import { CreateAdjustmentModalButton } from '@/components/adjustments/CreateAdjustmentModalButton.tsx';
import { EditAdjustmentModal } from '@/components/adjustments/EditAdjustmentModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { logger } from '@/utils/libs/logger.ts';

export function Adjustments() {
  const { preTradeDeals } = useLoaderData({ from: '/adjustments/' });

  const editAdjustmentModal = useModal(EditAdjustmentModal);
  const confirmDeleteModal = useModal(ConfirmDeleteAdjustmentModal);
  const [deletePreTradeDeal] = useDeletePreTradeDealMutation();
  const [editPreTradeDeal] = useEditPreTradeDealMutation();

  const dispatch = useAppDispatch();

  async function onDelete(tradeReference: string) {
    await confirmDeleteModal.show();
    try {
      await deletePreTradeDeal(tradeReference).unwrap();
    } catch (e) {
      logger.logError(`Fail to delete deal with id ${tradeReference} {error_s}`, JSON.stringify(e));
      dispatch(addErrorToastThunk(`Error trying to delete deal : ${tradeReference}`));
    }
  }

  async function onUpdate(tradeReference: string) {
    const preTradeDeal = (await editAdjustmentModal.show({
      tradeReference,
    })) as PreTradeDeal;
    try {
      await editPreTradeDeal(preTradeDeal).unwrap();
    } catch (e) {
      logger.logError(`Fail to Edit deal with id ${tradeReference} {error_s}`, JSON.stringify(e));
      dispatch(addErrorToastThunk(`Error trying to edit deal : ${tradeReference}`));
    }
  }

  const coldDefs = useMemo(() => {
    return getAdjustmentsColDef(onDelete, onUpdate);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="d-flex flex-between px-4 py-2 ">
        <div className="display-4">Daily adjustments</div>
        <CreateAdjustmentModalButton />
      </div>
      <div className="w-100 h-100">
        <AdjustmentsGrid pretradeDeals={preTradeDeals} colDefs={coldDefs} />
      </div>
    </>
  );
}

function getAdjustmentsColDef(onDelete: (id: string) => void, onUpdate: (id: string) => void) {
  const colDefs: ColDef<BlotterDeal>[] = [
    {
      field: 'productDescription',
      headerName: 'Underlying',
    },
    {
      field: 'sgSide',
      headerName: 'SgSide',
    },
    {
      field: 'portfolio',
      headerName: 'Portfolio',
    },
    {
      field: 'productQuantity',
      headerName: 'Product Quantity',
    },
    {
      field: 'productPrice',
      headerName: 'Product Price',
    },
    {
      field: 'amount',
      headerName: 'Amount',
    },
    {
      field: 'creationUser',
      headerName: 'Trader',
    },
    {
      headerName: 'Action',
      width: 130,
      cellClass: 'flex-center',
      cellRenderer: ({ node }: { node: RowNode<BlotterDeal> }) => (
        <>
          <Button
            className="m-2"
            onClick={() => {
              const xOneReference = node.data?.xOneReference;
              if (xOneReference) {
                onDelete(xOneReference);
              }
            }}
            icon
            variant="danger"
            flat
          >
            <em className="icon icon-sm flex-center">delete_outline</em>
          </Button>
          <Button
            className="m-2"
            onClick={() => {
              const xOneReference = node.data?.xOneReference;
              if (xOneReference) {
                onUpdate(xOneReference);
              }
            }}
            icon
            flat
          >
            <em className="icon icon-sm flex-center">edit</em>
          </Button>
        </>
      ),
    },
  ];
  return colDefs;
}
