import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import type { AppState } from '@/store/store.ts';
import { webApi } from '@/web/utils/webApi.ts';

// See https://iam-hom.sgmarkets.com/resources/3471728d-d004-4144-be49-8ee358698bf3#permissions
export type UserPermission =
  | 'viewRules'
  | 'editRules'
  | 'viewAdjustments'
  | 'viewEquityRisk' // See main Equity Risk grid with customizable metrics that can be displayed
  | 'seeAllCubes' // User can see all available cubes and pick one specifically
  | 'seeDevTools' // Enable user to see dev panel (query list & query editor) + change context values
  | 'viewHedger' // Get access to Hedger screen. Generate and manage list of hedge orders
  | 'useHedgerSourceGreat' // Enable picking GREAT as a data source for hedge orders generation
  | 'useHedgerSourceStorm' // Enable picking STORM as a data source for hedge orders generation
  // 'See All STORM Instances', // Backend only
  // 'fsi_amer_trader', // backend => storm instances
  | 'devci'; // Special - Not in IAM
// Matches profiles defined in IAM
export type UserRole =
  | 'Support'
  | 'Management'
  | 'TraderIDX'
  | 'TraderExo'
  | 'TraderDLP'
  | 'TraderFSI'
  | 'HedgerAmer'
  | 'ReadOnly'
  // Special profile for not onboarded users testing devci
  | 'Devci';

export interface UserState {
  user: CurrentUser | undefined;
}

const initialState: UserState = {
  user: undefined,
};
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },

  selectors: {
    maybeUser: sliceState => sliceState.user,
    user: sliceState => sliceState.user!,
    teams: createSelector([(userState: UserState) => userState.user!], user => user.teams),
    email: createSelector([(userState: UserState) => userState.user!], user => user.email),
    // hasPermission: createSelector(
    //   [
    //     (userState: UserState) => userState.user!,
    //     (_, permissions: UserPermission[]) => permissions,
    //   ],
    //   (user, permissions) => permissions.some(p => user.permissions.includes(p)),
    // ),
    hasAnyTeam: createSelector(
      [(userState: UserState) => userState.user!],
      user => user.teams.length > 0,
    ),
  },
});

interface CurrentUser {
  permissions: UserPermission[];
  role: UserRole;
  email: string;
  teams: string[];
  name: string;
}

export const fetchCurrentUser = createAsyncThunk<CurrentUser, void, { state: AppState }>(
  'user/fetchCurrentUser',
  async () => {
    return webApi.get('api/current-user').json<CurrentUser>();
  },
);

export function selectUserHasAnyPermission(...permission: UserPermission[]) {
  return (state: AppState) => permission.some(p => state.user.user!.permissions.includes(p));
}
