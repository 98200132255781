import { useMemo, useRef } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';

import type { MarketRef } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import type {
  AxisConfig,
  CurrencyType,
  HedgeAxis,
} from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import {
  getMarketConfigColDef,
  useFormatHedgeAxis,
} from '@/components/hedger/manage/scenarios/components/hedgeAxis.ts';

interface EditMarketConfigGridProps {
  axisConfig: AxisConfig[];
  currency: CurrencyType;
  hedgeAxis: HedgeAxis;
  recipients: Recipient[];
  markets: MarketRef[];
}

const defaultRowData = {
  active: true,
  hedgePercent: 0,
  greek: '',
  axisId: '',
  advParticipationPercent: 0,
  limitMax: 0,
  limitMin: 0,
  recipientId: 0,
};

// export const EditScenarioGrid = memo(EditMarketConfigsGrid);

export function EditAxisGrid({
  axisConfig,
  currency,
  hedgeAxis,
  recipients,
  markets,
}: EditMarketConfigGridProps) {
  const gridApi = useRef<GridApi>();
  const formatHedgeAxis = useFormatHedgeAxis();

  const marketConfigsColDef = useMemo(
    () => getMarketConfigColDef('edit', hedgeAxis, recipients, markets, currency, formatHedgeAxis),
    [recipients, markets, hedgeAxis, currency, formatHedgeAxis],
  );

  function onGridReady({ api }: GridReadyEvent<AxisConfig>) {
    api.sizeColumnsToFit();
    gridApi.current = api;
  }

  function addNewLine() {
    axisConfig.push({ ...defaultRowData, axisId: '' });
    gridApi.current?.setGridOption('rowData', axisConfig);
    gridApi.current?.ensureIndexVisible(axisConfig.length - 1);
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="display-5">{formatHedgeAxis(hedgeAxis)}</div>
        <Button discreet variant="primary" onClick={addNewLine}>
          Add new line
        </Button>
      </div>

      <AgGridReact<AxisConfig>
        modules={[ClientSideRowModelModule, RangeSelectionModule, RichSelectModule]}
        className="ag-theme-alpine ag-theme-era"
        columnDefs={marketConfigsColDef}
        rowData={axisConfig}
        onGridReady={onGridReady}
        enableRangeSelection
        singleClickEdit
      />
    </>
  );
}
