import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@/store/hooks.ts';

export function LiveUpdatesBanner(): JSX.Element | null {
  const liveUpdates = useAppSelector(state => state.draftQuery.liveUpdates);
  const pauseTime = useAppSelector(state => state.draftQuery.liveUpdatesPausedTime);

  return !liveUpdates ? (
    <div className="alert alert-discreet-warning py-8px mt-2" role="banner">
      <i className="icon icon-md lh-1 me-2">error_outline</i>
      <span className={'fw-bold'}>Attention: </span>
      <FormattedMessage id={'LiveUpdatesBanner.Warning'} values={{ pauseTime }} />
    </div>
  ) : null;
}
