import type { PropsWithChildren } from 'react';

type Role = 'primary' | 'danger' | 'info' | 'success';

interface AlertProps {
  role?: Role;
}

function getIcon(role: Role | undefined) {
  switch (role) {
    case 'primary':
      return 'info';
    case 'danger':
      return 'warning_amber';
    case 'info':
      return 'info';
    case 'success':
      return 'info';
  }
}

export function Alert({ children, role = 'danger' }: PropsWithChildren<AlertProps>) {
  return (
    <div className={`alert alert-outline-${role} mt-5 d-flex align-items-center`} role="alert">
      <em className="icon icon-lg pe-2">{getIcon(role)}</em>
      {children}
    </div>
  );
}
