import { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { FormattedMessage } from 'react-intl';

import {
  usePostOrderGroupGenerateFileMutation,
  type OrderGroup,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import { webApi } from '@/web/utils/webApi.ts';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import type { HedgerError } from '@/components/hedger/common/hedgerErrorSchema.ts';
import { InactiveOrdersBanner } from '@/components/hedger/order/HedgerOrderPage.tsx';
import { downloadBlob } from '@/utils/downloadBlob.ts';

type DownloadFileData = {
  orderGroupId: number;
  generatedFileId: number;
  fileName: string;
};

async function downloadFile({ generatedFileId, orderGroupId, fileName }: DownloadFileData) {
  const blob = await webApi
    .get(`api/hedger/order-groups/${orderGroupId}/generated-files/${generatedFileId}`)
    .blob();
  downloadBlob(`${fileName}.xlsx`, blob);
}

export const HedgerExportModal = NiceModal.create(function HedgerExportModal({
  orderGroup,
}: {
  orderGroup: OrderGroup;
}) {
  const modal = useModal();
  const orderGroupId = orderGroup.orderGroupId;

  const [generateOrderGroupFileTrigger, { isSuccess, data: orderGroupFilesData }] =
    usePostOrderGroupGenerateFileMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<HedgerError | undefined>();

  async function onDownloadClick() {
    if (orderGroupFilesData == null) {
      return;
    }
    try {
      setIsLoading(true);
      setError(undefined);
      for (const orderGroupFile of orderGroupFilesData.files) {
        await downloadFile({
          orderGroupId,
          generatedFileId: orderGroupFile.generatedFileId,
          fileName: orderGroupFile.fileName,
        });
      }
    } catch (error) {
      const err = error as HedgerError;
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }

  const columnLabels = ['Hedger.ExportTable.Application', 'Hedger.ExportTable.Action'] as const;

  return (
    <BootstrapModal
      style={{ minHeight: '28em', minWidth: '51em' }}
      footer={
        <Button className="ps-3 pe-3 pt-2 pb-2" variant="primary" onClick={() => modal.remove()}>
          Close
        </Button>
      }
      titleId="Hedger.ExportModal.Title"
    >
      {!isLoading && error !== undefined ? (
        <ErrorPanel messageId="Hedger.ExportModal.Error" />
      ) : null}
      <InactiveOrdersBanner orderGroup={orderGroup} />

      <table className="table table-sm table-bordered mt-3">
        <thead>
          <tr>
            {columnLabels.map(key => (
              <th key={`${key}`}>
                <FormattedMessage id={`${key}`} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-middle">ETS</td>
            <td>
              {!isLoading ? (
                <Button className="btn-icon-start" disabled={!isSuccess} onClick={onDownloadClick}>
                  <em className="icon">download</em>
                  Download
                </Button>
              ) : (
                <>
                  <div className="d-flex justify-content-around align-items-center">
                    <div className="spinner spinner-md" role="status" />
                  </div>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </BootstrapModal>
  );
});
