import { useState } from 'react';
import { useRouter, useSearch } from '@tanstack/react-router';
import omit from 'just-omit';
import { FormattedMessage } from 'react-intl';

import {
  useGetRefsTradingToolTemplatesQuery,
  type TradingToolTemplate,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import {
  useCreateRecipientMutation,
  useGetRecipientsQuery,
  type AdditionalField,
  type CreateRecipientData,
  type Recipient,
} from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { EditRecipientGrid } from '@/components/hedger/manage/recipients/components/EditRecipientGrid.tsx';
import {
  EditRecipientInputControls,
  type RecipientData,
} from '@/components/hedger/manage/recipients/components/EditRecipientInputControls.tsx';
import { isQueryHookResultReady } from '@/utils/rtk/predicates.ts';
import { getUserInfo } from '@/utils/sgwt/userPermission.ts';

function getRecipientInit(
  tradingToolTemplates: TradingToolTemplate[],
  teams: string[],
  recipientInit?: Recipient,
): Omit<Recipient, 'recipientId'> {
  const baseRecipientInit: Omit<Recipient, 'recipientId'> = {
    sharedWithScenarios: [],
    lastUpdateTime: '',
    team: teams[0],
    userId: getUserInfo()?.sesame_id || '',
    additionalFields: {
      hedgePortfolios: {
        Test1: {
          foo: 'foo',
        },
      },
      marketLetters: {
        Test1: 'foo',
      },
      accounts: {
        foo: 'foo',
      },
      startTime: {},
      endTime: {},
    },
    tradingToolTemplateId: tradingToolTemplates[0].tradingToolTemplateId,
    recipientLabel: '',
  };
  return recipientInit === undefined
    ? baseRecipientInit
    : {
        ...baseRecipientInit,
        ...recipientInit,
        userId: getUserInfo()?.sesame_id || '',
        recipientLabel: '',
        team: (teams.includes(recipientInit.team) ? recipientInit.team : teams[0]) ?? '',
      };
}

interface CreateRecipientFrameProps {
  close: () => void;
  createRecipient: (data: CreateRecipientData) => {
    unwrap: () => Promise<Recipient>;
  };
  tradingToolTemplates: TradingToolTemplate[];
  recipientInit: CreateRecipientData;
}

export function CreateRecipient(): JSX.Element {
  const teams = useAppSelector(userSlice.selectors.teams);

  const [createRecipient, { isLoading, isError, originalArgs }] = useCreateRecipientMutation();
  const getRecipientsResult = useGetRecipientsQuery();
  const getRefsResult = useGetRefsTradingToolTemplatesQuery();

  const { navigate } = useRouter();
  const { cloneId } = useSearch({ from: '/hedger/recipients/create' });
  const close = () => navigate({ to: '/hedger/recipients' });

  if (
    isLoading ||
    !isQueryHookResultReady(getRefsResult) ||
    !isQueryHookResultReady(getRecipientsResult)
  ) {
    return <Loader />;
  }

  if (getRefsResult.isError) {
    return <FetchErrorView error={getRefsResult.error} />;
  }

  if (getRecipientsResult.isError) {
    return <FetchErrorView error={getRecipientsResult.error} />;
  }

  const recipients = getRecipientsResult.data.recipients;
  const recipientToClone = recipients.find(({ recipientId }) => recipientId === cloneId);
  if (cloneId !== undefined && recipientToClone === undefined) {
    return (
      <ErrorPanel>
        No recipient of id <span className="fw-semibold">{cloneId}</span> .
      </ErrorPanel>
    );
  }

  const tradingToolTemplates = getRefsResult.data?.tradingToolTemplates;

  if (tradingToolTemplates === undefined || tradingToolTemplates.length === 0) {
    return <ErrorPanel> No trading tool templates available</ErrorPanel>;
  }

  const recipientInit: CreateRecipientData =
    isError && originalArgs !== undefined
      ? originalArgs
      : getRecipientInit(tradingToolTemplates, teams, recipientToClone);

  return (
    <CreateRecipientFrame
      recipientInit={recipientInit}
      createRecipient={createRecipient}
      tradingToolTemplates={tradingToolTemplates}
      close={close}
    />
  );
}

function CreateRecipientFrame(createRecipientFrameProps: CreateRecipientFrameProps) {
  const { close, createRecipient, tradingToolTemplates, recipientInit } = createRecipientFrameProps;
  const [recipientData, setRecipientData] = useState<RecipientData>(
    omit(recipientInit, ['additionalFields']),
  );

  const currentTradingToolTemplate =
    tradingToolTemplates.find(
      element => element.tradingToolTemplateId === recipientData.tradingToolTemplateId,
    ) ?? tradingToolTemplates[0];

  const [additionalFields, setAdditionalFields] = useState<Record<string, any>>(
    recipientInit.additionalFields,
  );

  async function onCreateClick() {
    const currentRecipient = {
      ...recipientInit,
      ...recipientData,
      tradingToolTemplateId: currentTradingToolTemplate.tradingToolTemplateId,
      additionalFields,
    };
    createRecipient(currentRecipient)
      .unwrap()
      .then(close)
      .catch(error => handleHedgerError('Error while creating recipient', error));
  }

  function updateAdditionalField(key: string, additionalField: AdditionalField) {
    setAdditionalFields(prevState => ({
      ...prevState,
      [key]: additionalField,
    }));
  }

  return (
    <div
      className="d-flex flex-column mx-auto flex-grow-1 w-75 gap-3 mb-3"
      style={{ minWidth: '60rem' }}
    >
      <div className="d-flex flex-column flex-grow-1 bg-lvl2 gap-3 p-3 border border-info">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="text-info fw-semibold">
            <FormattedMessage id="Hedger.ScenariosModal.CreateRecipient" />
          </h4>
          <div className="d-flex gap-2">
            <Button size="lg" onClick={close}>
              <FormattedMessage id="Hedger.ScenariosModal.CreateFrame.Cancel" />
            </Button>
            <Button size="lg" discreet className="btn-discreet-info " onClick={onCreateClick}>
              <FormattedMessage id="Hedger.ScenariosModal.CreateFrame.Create" />
            </Button>
          </div>
        </div>

        <EditRecipientInputControls
          recipientData={recipientData}
          onRecipientDataUpdate={setRecipientData}
          tradingToolTemplate={currentTradingToolTemplate}
          tradingToolTemplates={tradingToolTemplates}
        />
        <EditRecipientGrid
          additionalFields={additionalFields}
          updateAdditionalField={updateAdditionalField}
          tradingToolTemplate={currentTradingToolTemplate}
        />
      </div>
    </div>
  );
}
