import { useEffect, useId, useRef, useState, type ChangeEvent } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { FormattedMessage } from 'react-intl';
import { asSequence } from 'sequency';

import { useAppSelector } from '@/store/hooks.ts';
import { DEFAULT_PERIMETER_PRESET_KEY } from '@/store/slices/prefs/perimeterPresetsSlice.ts';
import type { PresetKey } from '@/store/slices/prefs/presetKey.ts';
import { DEFAULT_VISU_PRESET_KEY } from '@/store/slices/prefs/visualizationPresetsSlice.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { Alert } from '@/components/common/bootstrap/Alert.tsx';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';

export interface SavePresetModalProps {
  onSavePreset: (presetKey: PresetKey) => void;
  presetKeys: PresetKey[];
}

export const SavePresetModal = NiceModal.create<SavePresetModalProps>(
  ({ onSavePreset, presetKeys }) => {
    const teams = useAppSelector(userSlice.selectors.teams);
    const inputRef = useRef<HTMLInputElement>(null);

    const [inputPresetName, setInputPresetName] = useState('');
    const [isPresetNamePermitted, setIsPresetNamePermitted] = useState(true);

    const presetsByTeam = asSequence(presetKeys)
      .filterNot(
        p =>
          p.team === DEFAULT_VISU_PRESET_KEY.team || p.team === DEFAULT_PERIMETER_PRESET_KEY.team,
      )
      .groupBy(p => p.team);

    const [selectedTeam, setSelectedTeam] = useState(teams[0]);

    useEffect(() => {
      inputRef?.current?.focus();
      // eslint-disable-next-line
    }, [inputRef?.current]);

    if (teams.length === 0) {
      return (
        <BootstrapModal titleId="Preset.Save.Title">
          <Alert>You have no teams</Alert>
        </BootstrapModal>
      );
    }

    function onInputChange(event: ChangeEvent<HTMLInputElement>) {
      const value = event.target.value;
      const newPresetUpperCase = value.toUpperCase();

      const presetNames =
        presetsByTeam.get(selectedTeam)?.map(preset => preset.name.toUpperCase()) ?? [];
      const presetExists = presetNames.includes(newPresetUpperCase);
      setIsPresetNamePermitted(!presetExists);
      setInputPresetName(value);
    }

    const presetId = 'SaveViewModal' + useId();
    return (
      <BootstrapModal
        titleId="Preset.Save.Title"
        footer={
          <CancelConfirmFooter
            onConfirm={() => onSavePreset({ name: inputPresetName, team: selectedTeam })}
            confirmButtonProps={{ disabled: !isPresetNamePermitted }}
          />
        }
      >
        <>
          <div className="form-group-deprecated mt-2">
            <label htmlFor="team" className="form-label">
              Team
            </label>
            <Select
              id="team"
              itemsAsObjects={teams}
              selectedItem={selectedTeam}
              onChange={setSelectedTeam}
            />
          </div>

          <div className="form-group-deprecated mt-2">
            <label htmlFor={presetId} className="form-label">
              <FormattedMessage id="Preset.Modal.PresetName" />
            </label>

            <input
              className={`form-control ${!isPresetNamePermitted ? 'is-invalid' : ''}`}
              onChange={onInputChange}
              type="text"
              placeholder="Preset name"
              id={presetId}
              ref={inputRef}
            />
            <div className={`invalid-feedback ${!isPresetNamePermitted ? 'd-block' : ''}`}>
              <FormattedMessage id="Preset.Error.AlreadyExists" />
            </div>
          </div>

          <Alert role="info">This preset will be shared with the entire team.</Alert>
        </>
      </BootstrapModal>
    );
  },
);
