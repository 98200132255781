import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { assertUnreachable, isDefined } from '@sgme/fp';
import { z } from 'zod';

import { getMeasureType, type AllMeasureId } from '@/core/measures.ts';
import { validateAndLog } from '@/store/slices/prefs/validateAndLog.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import type { AppState, AppThunk } from '@/store/store.ts';
import { storeEventEmitter } from '@/store/storeEventEmitter.ts';
import { loadUserSessionService, saveUserSessionService } from '@/web/session/sessionApi.ts';
import { isColIdMeasureId } from '@/components/equityRisk/gridMappings/isColIdValid.ts';
import {
  backgroundColors,
  textColors,
  type ColorOfRule,
} from '@/components/prefs/tabs/ColorRules/colors.ts';

interface EditState {
  mode: 'edit';
  initialRule: ColorRule;
  index: number;
}

interface AddState {
  mode: 'add';
  index: number;
}

type EditionState = EditState | AddState;

interface ColorRulesState {
  rules: ColorRule[];
  editionState: EditionState | undefined;
}

export type ColorRuleOperator = 'greaterThan' | 'lesserThan';

const measureIdSchema = z.string().transform(m => (isColIdMeasureId(m) ? m : 'Spot0'));

const baseColorRuleSchema = z.object({
  name: z.string(),
  threshold: z.number(),
  enabled: z.boolean().default(true),
  operator: z.enum(['greaterThan', 'lesserThan']),
  bgColor: z.enum(backgroundColors).optional(),
  textColor: z.enum(textColors).optional(),
});

const columnColorRuleSchema = baseColorRuleSchema.merge(
  z.object({
    coloredMeasureId: measureIdSchema,
    conditionMeasureId: measureIdSchema,
  }),
);

const colorRuleSchema = z.discriminatedUnion('scope', [
  z.object({ scope: z.literal('allColumns') }).merge(baseColorRuleSchema),
  z.object({ scope: z.literal('singleColumn') }).merge(columnColorRuleSchema),
]);

export type ColorRule = z.infer<typeof colorRuleSchema>;
export type ColorRuleScope = 'allColumns' | 'singleColumn';

// for compat -- can be removed next versions
function addDefault(key: string, value: string, obj: unknown) {
  if (typeof obj === 'object' && obj != null && !obj.hasOwnProperty(key)) {
    Object.assign(obj, { [key]: value });
  }
  return obj;
}

function validateValues(data: unknown[]): ColorRule[] {
  return data
    .map(value => {
      const parsed = colorRuleSchema.safeParse(addDefault('scope', 'singleColumn', value));
      return parsed.success ? parsed.data : undefined;
    })
    .filter(isDefined);
}

const validationSchema = z.array(z.any()).transform(validateValues);

export function validateColorRules(array: unknown): ColorRule[] {
  return validateAndLog('ColorRules', validationSchema, array) ?? [];
}

const defaultRules: ColorRule[] = [
  {
    scope: 'allColumns',
    name: 'negative-values',
    textColor: 'text-danger',
    threshold: 0,
    operator: 'lesserThan',
    bgColor: undefined,
    enabled: true,
  },
  {
    scope: 'singleColumn',
    name: 'spot-success',
    coloredMeasureId: 'UnderlyerSpotRTMove',
    conditionMeasureId: 'UnderlyerSpotRTMove',
    textColor: 'text-success',
    threshold: 0,
    operator: 'greaterThan',
    bgColor: undefined,
    enabled: true,
  },
];

const initialColorRulesState: ColorRulesState = {
  editionState: undefined,
  rules: defaultRules,
};

export const colorsRulesSlice = createSlice({
  name: 'colorRules',
  initialState: initialColorRulesState,
  reducers: {
    setRules(state, action: PayloadAction<ColorRule[]>) {
      state.rules = action.payload;
    },
    setRuleForColumn(state, action: PayloadAction<{ rule: ColorRule; index: number }>) {
      const { rule, index } = action.payload;
      state.rules.splice(index, 1, rule);
    },
    deleteRule(state, action: PayloadAction<{ index: number }>) {
      state.rules.splice(action.payload.index, 1);
    },
    toggleRuleEnabled(state, action: PayloadAction<{ index: number; enabled: boolean }>) {
      const { index, enabled } = action.payload;
      state.rules[index].enabled = enabled;
    },
    moveRule(state, action: PayloadAction<{ fromIndex: number; toIndex: number }>) {
      const rules = state.rules;
      const { fromIndex, toIndex } = action.payload;
      const dragged = rules[fromIndex];
      state.rules = rules.toSpliced(fromIndex, 1).toSpliced(toIndex, 0, dragged);
    },
    editRule(state, action: PayloadAction<{ index: number }>) {
      const { index } = action.payload;
      state.editionState = {
        mode: 'edit',
        index,
        initialRule: state.rules[index],
      };
    },
    stopEdit(state) {
      state.editionState = undefined;
    },
    addRule(state) {
      const defaultRule: ColorRule = {
        scope: 'singleColumn',
        name: '',
        operator: 'greaterThan',
        bgColor: undefined,
        coloredMeasureId: 'UnderlyerSpotRT',
        textColor: undefined,
        threshold: 0,
        conditionMeasureId: 'UnderlyerSpotRT',
        enabled: true,
      };
      state.rules.push(defaultRule);
      state.editionState = {
        mode: 'add',
        index: state.rules.length - 1,
      };
    },
    rollbackChanges(state) {
      const editionState = state.editionState;
      if (editionState === undefined) {
        console.warn('Tried to rollback non-edition state');
        return;
      }

      if (editionState.mode === 'edit') {
        state.rules.splice(editionState.index, 1, editionState.initialRule);
      } else {
        state.rules.splice(editionState.index, 1);
      }
      state.editionState = undefined;
    },
    initRules(state, action: PayloadAction<{ colorRules: ColorRule[] }>) {
      const { colorRules } = action.payload;
      if (colorRules.length === 0) {
        state.rules = defaultRules;
      } else {
        state.rules = colorRules;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchColorRules.fulfilled, (state, action) => {
      const colorRules = action.payload;
      if (colorRules.length === 0) {
        state.rules = defaultRules;
      } else {
        state.rules = colorRules;
      }
    });
  },
});

export function getCoefficientForMeasure(measureId: AllMeasureId): number {
  const measureType = getMeasureType(measureId);
  switch (measureType) {
    case 'percentage':
      return 100;
    case 'currency':
      return 1 / 1000;
    case 'price':
    case 'text':
    case 'quantity':
      return 1;
    default:
      assertUnreachable(measureType, 'Unhandled measure type');
  }
}

export function selectColumnHasColor(
  state: AppState,
  measure: AllMeasureId,
  color: ColorOfRule,
  getMeasureValue: (measure: AllMeasureId) => any,
  columnValue: any,
): boolean {
  const firstMatchingRule = state.colorRules.rules.find(rule => {
    if (!rule.enabled) {
      return false;
    }
    const isMatchingColorType = rule[color.type] !== undefined;
    if (!isMatchingColorType) {
      return false;
    }

    return (
      (rule.scope === 'allColumns' && isRuleApplied(rule, columnValue)) ||
      (rule.scope === 'singleColumn' &&
        rule.coloredMeasureId === measure &&
        isRuleApplied(rule, getMeasureValue(rule.conditionMeasureId)))
    );
  });

  if (firstMatchingRule === undefined) {
    return false;
  }

  return firstMatchingRule[color.type] === color.cssClass;
}

function isRuleApplied(rule: ColorRule, value: any) {
  switch (rule.operator) {
    case 'greaterThan':
      return value >= rule.threshold;
    case 'lesserThan':
      return value <= rule.threshold;
  }
}

export function selectRuleByIndex(index: number) {
  return (state: AppState) => state.colorRules.rules[index];
}

export function replaceRulesThunk(rules: ColorRule[]): AppThunk {
  return async (dispatch, getState) => {
    dispatch(colorsRulesSlice.actions.initRules({ colorRules: rules }));
    await saveRules(getState());
    storeEventEmitter.emit('colorChanged', {});
  };
}

export function setRuleForColumnThunk(newRule: ColorRule, index: number): AppThunk {
  return dispatch => {
    dispatch(colorsRulesSlice.actions.setRuleForColumn({ rule: newRule, index }));
    storeEventEmitter.emit('colorChanged', {});
  };
}

export function setRulesThunk(rules: ColorRule[]): AppThunk {
  return async (dispatch, getState) => {
    dispatch(colorsRulesSlice.actions.setRules(rules));
    storeEventEmitter.emit('colorChanged', {});
    await saveRules(getState());
  };
}
export function moveRuleThunk(from: number, to: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch(colorsRulesSlice.actions.moveRule({ fromIndex: from, toIndex: to }));
    storeEventEmitter.emit('colorChanged', {});
    await saveRules(getState());
  };
}

export function deleteRuleThunk(index: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch(colorsRulesSlice.actions.deleteRule({ index }));
    storeEventEmitter.emit('colorChanged', {});
    await saveRules(getState());
  };
}

export function toggleRuleThunk(index: number, enabled: boolean): AppThunk {
  return async (dispatch, getState) => {
    dispatch(colorsRulesSlice.actions.toggleRuleEnabled({ index, enabled }));
    storeEventEmitter.emit('colorChanged', {});
    await saveRules(getState());
  };
}

export function rollbackRuleThunk(): AppThunk {
  return dispatch => {
    dispatch(colorsRulesSlice.actions.rollbackChanges());
    storeEventEmitter.emit('colorChanged', {});
  };
}

export function stopEditAndSaveRuleThunk(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(colorsRulesSlice.actions.stopEdit());
    await saveRules(getState());
  };
}

async function saveRules(state: AppState) {
  const rules = state.colorRules.rules;
  const email = userSlice.selectors.email(state);
  await saveUserSessionService(email, 'colorRules', rules, validationSchema);
}

export const fetchColorRules = createAsyncThunk<ColorRule[], void, { state: AppState }>(
  'colorRules/fetchColorRules',
  async (_, { getState }) => {
    const user = userSlice.selectors.user(getState());
    return loadUserSessionService(user.email, 'colorRules').then(validateColorRules);
  },
);
