import { useCallback, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import type { EditAdditionalFieldsGrid } from '@/components/hedger/manage/recipients/components/EditRecipientGrid.tsx';
import {
  EditTable,
  type RowData,
  type Updater,
} from '@/components/hedger/manage/recipients/details/EditTable.tsx';
import {
  mapFromRowDatas,
  mapToRowDatas,
} from '@/components/hedger/manage/recipients/details/mappers.ts';
import type { IntlMessages } from '@/types/intl';

export type EditTableModalProps = {
  name: string;
  titleId?: IntlMessages;
  updateAdditionalField: EditAdditionalFieldsGrid['updateAdditionalField'];
  data: Record<string, string>;
};

export const EditTableModal = NiceModal.create(function EditTableModal(props: EditTableModalProps) {
  const { data: dataInit, titleId, name, updateAdditionalField } = props;

  const modal = useModal();

  const [rowDatas, setRowDatas] = useState<RowData[]>(mapToRowDatas(dataInit));

  const onEditTableUpdate = useCallback((updater: Updater<RowData[]>) => {
    setRowDatas(data => updater(data));
  }, []);

  const onConfirmClick = () => {
    const additionalField = mapFromRowDatas(rowDatas);

    updateAdditionalField(name, additionalField);
    modal.remove();
  };

  return (
    <BootstrapModal
      style={{ minHeight: '28em', minWidth: '51em' }}
      footer={
        <div className="d-flex gap-1">
          <Button className={'ps-3 pe-3 pt-2 pb-2'} flat size="lg" onClick={() => modal.remove()}>
            Cancel
          </Button>
          <Button
            className={'ps-3 pe-3 pt-2 pb-2'}
            variant="primary"
            size="lg"
            onClick={onConfirmClick}
          >
            Confirm
          </Button>
        </div>
      }
      titleId={titleId}
    >
      <EditTable rowDatas={rowDatas} onUpdate={onEditTableUpdate} />
    </BootstrapModal>
  );
});
