import { useMemo, useReducer, type Reducer } from 'react';

import { objectKeys } from '@/utils/libs/entries.ts';

type Action<A> = {
  type: A;
  payload?: any;
};

type WrappedMethods<State, M extends Record<string, (payload?: any) => State>> = {
  [P in keyof M]: (...payload: Parameters<M[P]>) => void;
};

export function useMethods<State, Methods extends Record<string, (payload?: any) => State>>(
  createMethods: (initialState: State) => Methods,
  initialState: State,
): [State, WrappedMethods<State, Methods>] {
  const reducer = useMemo<Reducer<State, Action<keyof Methods>>>(
    () => (reducerState: State, action: Action<keyof Methods>) => {
      const methods = createMethods(reducerState);
      const methodToCall = methods[action.type];

      return methodToCall(...action.payload);
    },
    [createMethods],
  );

  const [state, dispatch] = useReducer<Reducer<State, Action<keyof Methods>>>(
    reducer,
    initialState,
  );

  const wrappedMethods: WrappedMethods<State, Methods> = useMemo(() => {
    const actionTypes = objectKeys(createMethods(initialState));

    return actionTypes.reduce(
      (acc, type) => {
        acc[type] = (...payload: Parameters<Methods[typeof type]>) => dispatch({ type, payload });
        return acc;
      },
      {} as WrappedMethods<State, Methods>,
    );
  }, [createMethods, initialState]);

  return [state, wrappedMethods];
}
