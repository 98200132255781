import { useId, type JSX } from 'react';
import { FormattedMessage } from 'react-intl';

import { useResetRecipientsMutation } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { useResetScenariosMutation } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import {
  negativeNumberFormatValues,
  numberFormatValues,
} from '@/store/slices/prefs/userPreferencesSlice.ts';
import type { CubeInstanceUI } from '@/store/slices/ui/uiSlice.ts';
import { selectUserHasAnyPermission } from '@/store/slices/user/userSlice.ts';
import { AutoCompleteCombo } from '@/components/common/bootstrap/AutoCompleteCombo.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { ToggleButton } from '@/components/common/bootstrap/ToggleButton.tsx';
import type { DisplayDataPrefs } from '@/components/prefs/UserPrefs.tsx';
import { isDevEnv } from '@/utils/config/configUtils.ts';
import { currencyValues } from '@/utils/libs/currency.ts';
import type { Theme } from '@/utils/libs/theme.ts';

interface DisplayDataTabProps {
  userPrefs: DisplayDataPrefs;
  setUserPrefs: (prefs: DisplayDataPrefs) => void;
}

const switchToggleButtonValues = ['On', 'Off'] as const;
type SwitchToggleButtonValue = (typeof switchToggleButtonValues)[number];

export function DisplayDataTab({ userPrefs, setUserPrefs }: DisplayDataTabProps) {
  const idCurrency = `currency-${useId()}`;
  const idResetScenarios = `scenarios-${useId()}`;
  const cubeInstances = useAppSelector(state => state.ui.cubeInstances);
  const [resetScenarios] = useResetScenariosMutation();
  const [resetRecipients] = useResetRecipientsMutation();

  function userPrefSetter(
    prop: keyof DisplayDataPrefs,
  ): (value: DisplayDataPrefs[typeof prop]) => void {
    return value => {
      setUserPrefs({
        ...userPrefs,
        [prop]: value,
      });
    };
  }

  const canSeeCubesPerm = useAppSelector(selectUserHasAnyPermission('seeAllCubes'));
  const canSeeDevToolsPerm = useAppSelector(selectUserHasAnyPermission('seeDevTools'));
  const canSeeCubes = cubeInstances.length > 0 && canSeeCubesPerm;
  return (
    <div className="d-flex flex-column">
      <ToggleButton<SwitchToggleButtonValue>
        formatId="UserPrefs.Section.Flash"
        activeValue={userPrefs.flashCell ? 'On' : 'Off'}
        values={switchToggleButtonValues}
        onClick={value => userPrefSetter('flashCell')(value === 'On')}
      />

      <ToggleButton<SwitchToggleButtonValue>
        formatId="UserPrefs.Section.MonitorTiming"
        activeValue={userPrefs.monitorExecutionTiming ? 'On' : 'Off'}
        values={switchToggleButtonValues}
        onClick={value => userPrefSetter('monitorExecutionTiming')(value === 'On')}
        hide={!canSeeDevToolsPerm}
      />
      <ToggleButton<SwitchToggleButtonValue>
        formatId="UserPrefs.Section.MonitorPlan"
        activeValue={userPrefs.monitorExecutionPlan ? 'On' : 'Off'}
        values={switchToggleButtonValues}
        onClick={value => userPrefSetter('monitorExecutionPlan')(value === 'On')}
        hide={!canSeeDevToolsPerm}
      />
      <ToggleButton<Theme>
        formatId="UserPrefs.Section.Theme"
        activeValue={userPrefs.theme}
        values={['DARK', 'LIGHT']}
        onClick={value => userPrefSetter('theme')(value)}
      />

      {canSeeCubes && (
        <div>
          <label htmlFor="cubeInstances" className="form-label">
            <FormattedMessage id="UserPrefs.CubeInstance" />
          </label>
          <AutoCompleteCombo<CubeInstanceUI>
            id="cubeInstances"
            initialItems={cubeInstances}
            ItemRenderer={CubeInstanceRenderer}
            onChange={item => userPrefSetter('selectedCubeInstanceName')(item.cubeHostName)}
            selectedItem={
              cubeInstances.find(i => i.cubeHostName === userPrefs.selectedCubeInstanceName)!
            }
            itemToString={i => `${i?.cubeHostName}`}
          />
        </div>
      )}

      <ToggleButton
        formatId="UserPrefs.Section.NegativeNumber"
        activeValue={userPrefs.negativeNumberFormat}
        values={negativeNumberFormatValues}
        onClick={value => userPrefSetter('negativeNumberFormat')(value)}
      />
      <ToggleButton
        formatId={'UserPrefs.Section.NumberFormat'}
        activeValue={userPrefs.numberFormat}
        values={numberFormatValues}
        formatter={value => (value === 'en' ? 'UK (ex. 10,000.00)' : 'FR (ex. 10 000,00)')}
        onClick={value => userPrefSetter('numberFormat')(value)}
      />

      <div className="form-group-deprecated">
        <label htmlFor={idCurrency} className="form-label">
          <FormattedMessage id="UserPrefs.Currency" />
        </label>
        <Select
          id={idCurrency}
          className="form-control w-25"
          itemsAsObjects={[...currencyValues]}
          selectedItem={userPrefs.currencyValue}
          onChange={userPrefSetter('currencyValue')}
        />
      </div>

      {isDevEnv() ? (
        <div>
          <div className="form-group-deprecated">
            <label htmlFor={idResetScenarios} className="form-label">
              Reset scenarios
            </label>
            <div>
              <Button
                className="form-control w-25"
                id={idResetScenarios}
                onClick={() => resetScenarios()}
              >
                Reset
              </Button>
            </div>
          </div>
          <div className="form-group-deprecated">
            <label htmlFor={idResetScenarios} className="form-label">
              Reset recipients
            </label>
            <div>
              <Button
                className="form-control w-25"
                id={idResetScenarios}
                onClick={() => resetRecipients()}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      <ToggleButton
        formatId={'UserPrefs.Widgets.Limit'}
        activeValue={userPrefs.topPanelWidgetLimit}
        values={[undefined, 3]}
        formatter={value => (value === undefined ? 'Show all widgets' : `Top ${value}`)}
        onClick={value => userPrefSetter('topPanelWidgetLimit')(value)}
      />
    </div>
  );
}

function simplifyWsUrl(item: CubeInstanceUI) {
  return item.webSocketUrl !== undefined ? new URL(item.webSocketUrl).hostname : '';
}

function CubeInstanceRenderer({ item }: { item: CubeInstanceUI }): JSX.Element {
  const url = simplifyWsUrl(item);
  return (
    <div className="d-flex flex-column">
      <div className="fw-bold">{item.cubeHostName}</div>
      <div>{url}</div>
    </div>
  );
}
