import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { RowData } from '@/core/parsing/parseResponse.ts';
import {
  fetchAnalyticalStructure,
  fetchCurrencyCache,
  fetchProfitCenters,
} from '@/store/slices/queryCache/queryCacheThunks.ts';
import { store } from '@/store/store.ts';
import { reducePerimeterKeys } from '@/components/gridTable/tools/reducePerimeterKeys.ts';

export interface AnalyticalStructure {
  GOP: string;
  PortFolio: string;
  ProfitCenter: string;
}
export type AnalyticalStructureRowData = RowData & AnalyticalStructure;

export interface ProfitCenterLongNameRowData extends RowData {
  ProfitCenter: string;
  ProfitCenterDesc: string;
}

export interface UnderlyerCacheRowData extends RowData {
  UnderlyerCode: string;
  UnderlyerCurrency: string;
}

interface CacheState {
  analyticalStructure: AnalyticalStructureRowData[];
  profitCenterDescRowData: ProfitCenterLongNameRowData[];
  underlyerCacheRowData: UnderlyerCacheRowData[];
}

const initialState: CacheState = {
  analyticalStructure: [],
  profitCenterDescRowData: [],
  underlyerCacheRowData: [],
};

export const queryCacheSlice = createSlice({
  name: 'queryCache',
  initialState: initialState,
  reducers: {},
  selectors: {
    analyticalStructureRowData(state): AnalyticalStructureRowData[] {
      return state.analyticalStructure;
    },
    perimeterKeys: createSelector(
      [(state: CacheState) => state.analyticalStructure],
      analyticalStructureRowData => {
        return getPerimeterKeys(analyticalStructureRowData);
      },
    ),
    reducedPerimeterKeys: createSelector(
      [(state: CacheState) => state.analyticalStructure],
      analyticalStructureRowData => {
        const perimeterKeys = getPerimeterKeys(analyticalStructureRowData);
        return reducePerimeterKeys(perimeterKeys, perimeterKeys);
      },
    ),
    getProfitCenterDescByCode: createSelector(
      [(state: CacheState) => state.profitCenterDescRowData],
      profitCenterDescRowData => {
        return new Map(
          profitCenterDescRowData.map(row => [row.ProfitCenter, row.ProfitCenterDesc]),
        );
      },
    ),
    profitCenters: createSelector(
      [(state: CacheState) => state.analyticalStructure],
      analyticalStructure => {
        return [...new Set(analyticalStructure.map(a => a.ProfitCenter))];
      },
    ),
    isStateInitialized(state) {
      return state.analyticalStructure.length > 0;
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchAnalyticalStructure.fulfilled, (state, action) => {
      state.analyticalStructure = action.payload;
    });
    builder.addCase(fetchCurrencyCache.fulfilled, (state, action) => {
      state.underlyerCacheRowData = action.payload;
    });
    builder.addCase(fetchProfitCenters.fulfilled, (state, action) => {
      state.profitCenterDescRowData = action.payload;
    });
  },
});

const cacheDataRowByUnderlyerCode = createSelector(
  [state => state.queryCache.underlyerCacheRowData],
  underlyerCacheRowData => {
    const result: Record<string, UnderlyerCacheRowData> = {};
    for (const rowDatum of underlyerCacheRowData) {
      const key = rowDatum.UnderlyerCode;
      result[key] = rowDatum;
    }
    return result;
  },
);

export const selectCurrencyGetter = createSelector(
  [cacheDataRowByUnderlyerCode],
  cacheDataRowByUnderlyerCode => {
    return (dataPath: string[]) => {
      // FIXME: is there another way? We want this to be lazy (when the currencyGetter is called)
      // We don't want the selector to trigger a re-render when hierarchies change
      const selectedHierarchies = store.getState().query.selectedHierarchies;
      const codeLevel = selectedHierarchies.findIndex(value => value === 'UnderlyerCode');
      const hierarchyValue = dataPath[codeLevel];
      const isLastLevel = dataPath.length - 1 === codeLevel;

      if (hierarchyValue !== undefined && isLastLevel) {
        const underlyerCacheRowDatum = cacheDataRowByUnderlyerCode[hierarchyValue];
        return underlyerCacheRowDatum?.UnderlyerCurrency;
      }

      return undefined;
    };
  },
);

function getPerimeterKeys(analyticalStructureRowData: AnalyticalStructureRowData[]): string[] {
  return analyticalStructureRowData.map(
    ({ ProfitCenter, GOP, PortFolio }) => `[${ProfitCenter}].[${GOP}].[${PortFolio}]`,
  );
}
