import type { ReactNode } from 'react';

import { useGetRefsMarketsQuery, type MarketRef } from '@/store/api/hedgerApi/hedgerApi.ts';
import {
  useGetRecipientsQuery,
  type Recipient,
} from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { useGetScenariosQuery, type Scenario } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';

type QueryData =
  | {
      state: 'uninitialized';
      Component: ReactNode;
    }
  | {
      state: 'data';
      recipients: Recipient[];
      scenarios: Scenario[];
      markets: MarketRef[];
    };

export function useScenarioQueryData(): QueryData {
  const getScenariosQuery = useGetScenariosQuery();
  const getRecipientsQuery = useGetRecipientsQuery();
  const getRefsMarketsQuery = useGetRefsMarketsQuery();

  if (
    getScenariosQuery.isLoading ||
    getScenariosQuery.isUninitialized ||
    getRecipientsQuery.isLoading ||
    getRecipientsQuery.isUninitialized ||
    getRefsMarketsQuery.isLoading ||
    getRefsMarketsQuery.isUninitialized
  ) {
    return {
      state: 'uninitialized',
      Component: <Loader />,
    };
  }
  if (getScenariosQuery.isError) {
    return {
      state: 'uninitialized',
      Component: <FetchErrorView error={getScenariosQuery.error} />,
    };
  }
  if (getRecipientsQuery.isError) {
    return {
      state: 'uninitialized',
      Component: <FetchErrorView error={getRecipientsQuery.error} />,
    };
  }
  if (getRefsMarketsQuery.isError) {
    return {
      state: 'uninitialized',
      Component: <FetchErrorView error={getRefsMarketsQuery.error} />,
    };
  }

  const recipients = getRecipientsQuery.data.recipients;
  const scenarios = getScenariosQuery.data.scenarios;
  const markets = getRefsMarketsQuery.data.markets;
  return { state: 'data', recipients, scenarios, markets };
}
