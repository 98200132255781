import type { ColDef } from '@ag-grid-community/core';
import { isDefined } from '@sgme/fp';

import {
  rulesEntityTypes,
  rulesModels,
  type RmmColumnDefinitions,
  type RuleCategory,
  type RulesModel,
} from '@/store/api/rulesApi/rulesModels.ts';
import type { AnalyticalStructure } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { isRuleLineVisibleGetter } from '@/components/rules/isRuleLineVisibleGetter.ts';
import { parseBooleanOrBooleanLike, parseFloatValue } from '@/components/rules/parsers.ts';
import {
  BaseRowDataManager,
  type RuleRowData,
} from '@/components/rules/rowDataManager/BaseRowDataManager.ts';

export class ModalRowDataManager extends BaseRowDataManager {
  private externalDuplicatedEntries: Map<string, boolean> = new Map();

  constructor(
    category: RuleCategory,
    rules: RulesModel[],
    analyticalStructures: AnalyticalStructure[],
  ) {
    super(category, rules, analyticalStructures);

    //the grid should always have one empty row to allow pasting in ag-grid see : https://www.ag-grid.com/react-data-grid/clipboard/
    this.addEmptyRow();
  }

  deleteRowById(rowId: number) {
    const rowIndex = this.rowData.findIndex(r => r.id === rowId);
    this.rowData.splice(rowIndex, 1);
    this.computeDuplicated();
  }

  isInvalidGrid(columnDefs: ColDef<RuleRowData>[] | undefined): boolean {
    if (this.rowData.length === 0) {
      return true;
    }

    return this.rowData.some(row => {
      const filteredColumnDefs = columnDefs?.filter(
        columnDef => isDefined(columnDef.field) && columnDef.headerName !== 'Delete',
      );

      return filteredColumnDefs?.some(columnDef => {
        return columnDef.field
          ? !this.isValidValue(columnDef.field, row[columnDef.field], row['entityType'])
          : true;
      });
    });
  }

  /**
   * if there is only empty row, it is considered initial state
   */
  isInitialGridState(): boolean {
    if (this.rowData.length === 1) {
      const firstRowDatum = this.rowData[0];
      const rowIsEmpty = rulesModels[this.category].every(column => {
        if (column.columnName === 'exclude') {
          return firstRowDatum[column.columnName] === true;
        }
        // @ts-ignore: todo fix type
        return firstRowDatum[column.columnName] === null;
      });

      if (firstRowDatum.metric === null && rowIsEmpty) {
        return true;
      }
    }

    return false;
  }

  updateRowData(newRowData: RuleRowData[]) {
    this.rowData = newRowData.map(row => {
      row.id = this.currentAddedId--;
      // @ts-ignore
      row.topLevelRuleId = null;
      return row;
    });
  }

  isValidValue(
    columnName: string | undefined,
    value: any,
    entityType: string | number | boolean,
  ): boolean {
    if (value === '') {
      return true;
    }

    if (columnName === 'iddef' && value == null) {
      return true;
    }

    if (columnName === 'metric') {
      return this.ruleIdByMetric.has(value);
    }

    if (columnName === 'entityType') {
      return rulesEntityTypes.includes(value);
    }

    if (columnName === 'entityName') {
      return isRuleLineVisibleGetter(this.analyticalStructures)({
        entityName: value,
        entityType,
      });
    }

    const columnDefinition = rulesModels[this.category].find(
      columnDef => columnDef.columnName === columnName,
    );
    if (columnDefinition === undefined) {
      return false;
    }

    try {
      this.getParsedValue(columnDefinition, value);
      return true;
    } catch {
      return false;
    }
  }

  computeDuplicated(externalRowData?: RuleRowData[]): void {
    super.computeDuplicated();
    if (externalRowData == null) {
      return;
    }
    const map = new Map(this.rowData.map(row => [this.getKey(row), [row]]));
    for (const externalRow of externalRowData) {
      const key = this.getKey(externalRow);

      if (map.has(key)) {
        this.externalDuplicatedEntries.set(key, true);
      }
    }
  }
  isDuplicatedFromExternal(rowData: RuleRowData): boolean {
    const key = this.getKey(rowData);
    return this.externalDuplicatedEntries.has(key);
  }

  getParsedValue(columnDefinition: RmmColumnDefinitions[RuleCategory][number], value: string) {
    switch (columnDefinition.columnType) {
      case 'STRING':
        return value;
      case 'BOOLEAN':
        return parseBooleanOrBooleanLike(value);
      case 'DOUBLE':
      case 'LONG':
        return parseFloatValue(value);
    }
  }
}
