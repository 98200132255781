import { useModal } from '@ebay/nice-modal-react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import { Button } from '@/components/common/bootstrap/Button.tsx';
import { ManageWidgetsModal } from '@/components/equityRisk/widgets/Modals/ManageWidgetsModal.tsx';

export function AddWidgetButton() {
  const manageWidgetsModal = useModal(ManageWidgetsModal);
  const { trackEvent } = useMatomo();

  function openAddWidgetModal() {
    trackEvent({ category: 'Widgets', action: 'Open widgets modal' });
    return manageWidgetsModal.show();
  }

  return <Button onClick={openAddWidgetModal}>Add Widget</Button>;
}
