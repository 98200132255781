import { useEffect, type PropsWithChildren } from 'react';
import { animations } from '@formkit/drag-and-drop';
import { useDragAndDrop } from '@formkit/drag-and-drop/react';
import classNames from 'classnames';

import { useAppDispatch } from '@/store/hooks.ts';
import {
  colorsRulesSlice,
  deleteRuleThunk,
  setRulesThunk,
  toggleRuleThunk,
  type ColorRule,
} from '@/store/slices/colorRules/colorsRulesSlice.ts';
import { Dropdown } from '@/components/common/bootstrap/Dropdown.tsx';
import { Switcher } from '@/components/common/bootstrap/Switcher.tsx';

interface ColorRulesProps {
  colorRules: ColorRule[];
}
export function ColorRules(props: ColorRulesProps) {
  const dispatch = useAppDispatch();
  const { colorRules } = props;

  const [colorRulesRef, currentColorRules, setCurrentColorRules] = useDragAndDrop<
    HTMLUListElement,
    ColorRule
  >(colorRules, {
    group: 'currentColorRules',
    dragHandle: '.colorcoding-draghandle',
    plugins: [animations()],

    onDragend: event => dispatch(setRulesThunk(event.values as ColorRule[])),
    draggingClass: 'dnd-grabzone',
  });

  useEffect(() => {
    setCurrentColorRules(colorRules);
  }, [colorRules, setCurrentColorRules]);

  function onEdit(index: number) {
    dispatch(colorsRulesSlice.actions.editRule({ index }));
  }

  function onDisable(index: number, enabled: boolean) {
    dispatch(toggleRuleThunk(index, enabled));
  }

  function onDelete(index: number) {
    dispatch(deleteRuleThunk(index));
  }

  return (
    <ul ref={colorRulesRef} className="d-flex flex-column list-unstyled">
      {currentColorRules.map((rule, index) => (
        <li key={rule.name} data-e2e="rule" className=" cursor-grab">
          <RuleCard
            key={rule.name}
            index={index}
            rule={rule}
            onToggle={checked => onDisable(index, checked)}
            onEdit={() => onEdit(index)}
            onDelete={() => onDelete(index)}
          >
            <span className={`${rule.bgColor} ${rule.textColor}`}>{rule.name}</span>
          </RuleCard>
        </li>
      ))}
    </ul>
  );
}

type RuleCardProps = {
  index: number;
  rule: ColorRule;
  onToggle: (checked: boolean) => void;
  onEdit: () => void;
  onDelete: () => void;
};

function RuleCard(props: PropsWithChildren<RuleCardProps>) {
  const { index, rule, children, onToggle, onEdit, onDelete } = props;
  function handleDropdownClick(action: 'edit' | 'delete') {
    if (action === 'delete') {
      onDelete();
    } else {
      onEdit();
    }
  }

  return (
    <div className="card card-raising card-bordered m-1 bg-lvl2">
      <div className="d-flex card-body p-2 justify-content-between gap-2 align-items-center cursor-grab colorcoding-draghandle">
        <div className="d-flex align-items-center">
          <i
            className={classNames('icon icon-md cursor-grab', {
              'text-dark': !rule.enabled,
            })}
            style={{ minWidth: 20 }}
          >
            drag_indicator
          </i>
          <span
            className={classNames('badge rounded-pill', {
              'bg-primary': rule.enabled,
              'bg-dark': !rule.enabled,
            })}
          >
            {index + 1}
          </span>
        </div>

        <div>{children}</div>

        <div className="d-flex align-items-center gap-2">
          <Dropdown
            itemsAsObjects={['edit', 'delete']}
            ItemRenderer={ItemRenderer}
            onItemClick={handleDropdownClick}
          >
            <span
              className={classNames({
                'text-dark': !rule.enabled,
              })}
            >
              Actions
            </span>
          </Dropdown>
          <Switcher checked={rule.enabled} onChange={onToggle} />
        </div>
      </div>
    </div>
  );
}

function ItemRenderer({ item }: { item: 'edit' | 'delete' }) {
  switch (item) {
    case 'delete':
      return <span className="text-danger">Delete</span>;
    case 'edit':
      return <span>Edit</span>;
  }
}
