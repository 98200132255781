interface BlotterCriteria {
  AssetClass: {
    Operator: string;
    Values: string[];
  };
  InstrumentName: {
    Operator: string;
    Values: string[];
  };
  InputTime: {
    Operator: string;
    DateOne: string;
    DateTwo: string;
  };
}

export function getBlotterCriteria(): BlotterCriteria {
  // const now = new Date().toISOString().split('T')[0];

  return {
    AssetClass: {
      Operator: 'EQUAL',
      Values: ['EQUITYLISTED'],
    },
    InstrumentName: {
      Operator: 'EQUAL',
      Values: ['EquitySecurity'],
    },
    InputTime: {
      Operator: 'BETWEEN',
      DateOne: '2024-11-13T10:00:00.000Z',
      DateTwo: '2024-11-14T10:30:00.000Z',
    },
  };
}
