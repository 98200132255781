import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';

export const ConfirmDeleteAdjustmentModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <BootstrapModal
      titleId={'Adjustment.ConfirmDeleteModalTitle'}
      footer={
        <CancelConfirmFooter
          onConfirm={() => modal.resolve()}
          confirmButtonProps={{
            component: 'Delete',
            variant: 'danger',
          }}
        />
      }
    >
      <div className="mt-5">Are you sure you want to delete this deal ?</div>
    </BootstrapModal>
  );
});
