import type { SaveViewChoiceType } from './saveViewChoices.ts';
import { useIntl } from 'react-intl';

import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Dropdown } from '@/components/common/bootstrap/Dropdown.tsx';

interface SaveViewProps {
  hidden: boolean;
  onCancel: () => void;
  onChange: (choice: SaveViewChoiceType) => void;
  saveChoices: SaveViewChoiceType[];
  align?: 'left' | 'right';
  id?: string;
}

export function SaveView({ hidden, onCancel, onChange, saveChoices, align, id }: SaveViewProps) {
  const { formatMessage } = useIntl();
  return (
    <>
      <Button hidden={hidden} onClick={onCancel}>
        Cancel
      </Button>

      <Dropdown<SaveViewChoiceType>
        id={id}
        variant="info"
        align={align}
        hidden={hidden}
        onItemClick={onChange}
        labelToString={() => formatMessage({ id: 'Preset.Save.Label' })}
        itemToString={choice => formatMessage({ id: `Preset.Save.${choice}` })}
        itemsAsObjects={saveChoices}
      />
    </>
  );
}
