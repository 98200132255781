import { useRef } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { GridApi, GridOptions } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { useModal } from '@ebay/nice-modal-react';

import type { RuleCategory, RulesModel } from '@/store/api/rulesApi/rulesModels.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { NoRows } from '@/components/common/utils/NoRowsOverlay.tsx';
import { ShowIf } from '@/components/common/utils/ShowIf.tsx';
import { AddClauseBatchModal } from '@/components/rules/addRulesBatchModal/AddRulesBatchModal.tsx';
import type { RuleRowData } from '@/components/rules/rowDataManager/BaseRowDataManager.ts';
import type { TableMode } from '@/components/rules/RulesCategoryComponent.tsx';

interface RulesGridProps<T> extends GridOptions<T> {
  category: RuleCategory;
  metricsValues: string[];
  mode: TableMode;
  onAddRow: () => void;
  rules?: RulesModel[];
  importDataFromRulesGrid: (rowDataToAdd: RuleRowData[]) => void;
}

export function RulesGrid<T>({
  onGridReady,
  mode,
  onAddRow,
  category,
  metricsValues,
  rules,
  importDataFromRulesGrid,
  rowData,
  ...props
}: RulesGridProps<T>) {
  const gridApiRef = useRef<GridApi<T> | undefined>();
  const addClauseBatchModal = useModal(AddClauseBatchModal);

  function handleSearchChange(text: string) {
    gridApiRef.current?.setGridOption('quickFilterText', text);
  }

  const openAddRulesBatchModal = () =>
    addClauseBatchModal.show({
      category,
      defaultColDef: props.defaultColDef,
      metricsValues,
      rules,
      importDataFromRulesGrid,
      externalRowData: rowData as RuleRowData[],
    });

  return (
    <div className="d-flex flex-column flex-grow-1 gap-2 mt-2">
      <div className="d-flex flex-between">
        <div className="input-group input-group-lg">
          <div className="input-icon-start">
            <em className="icon">search</em>
          </div>
          <input
            style={{ maxWidth: 400 }}
            type="search"
            className="form-control form-control-lg"
            placeholder="Search by any parameter"
            onChange={e => handleSearchChange(e.target.value)}
          />
        </div>
        <ShowIf condition={mode === 'edit'}>
          <Button className="btn-icon-end btn-lg text-nowrap" onClick={openAddRulesBatchModal}>
            Add clause batch <em className="icon">content_copy</em>
          </Button>
          <Button className="btn-icon-end btn-lg text-nowrap" onClick={onAddRow}>
            Add single clause <em className="icon">add</em>
          </Button>
        </ShowIf>
      </div>

      <AgGridReact<T>
        className="ag-theme-alpine ag-theme-era"
        rowData={rowData}
        modules={[ClientSideRowModelModule, RichSelectModule]}
        noRowsOverlayComponent={NoRows}
        onGridReady={e => {
          onGridReady?.(e);
          gridApiRef.current = e.api;
        }}
        gridOptions={{
          suppressMenuHide: false,
        }}
        onModelUpdated={e => {
          if (e.api.getModel().isRowsToRender()) {
            e.api.hideOverlay();
          } else {
            e.api.showNoRowsOverlay();
          }
        }}
        {...props}
      />
    </div>
  );
}
