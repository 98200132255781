import { useEffect } from 'react';
import { animations } from '@formkit/drag-and-drop';
import { useDragAndDrop } from '@formkit/drag-and-drop/react';
import { isDefined } from '@sgme/fp';
import classNames from 'classnames';
import { Resizable } from 're-resizable';

import type { WebSocketConnection } from '@/core/webSocket/types.ts';
import type { WidgetDef } from '@/store/slices/widget/widgetTypes.ts';
import type { WidgetParams } from '@/components/equityRisk/widgets/allWidgets.ts';
import { Widget } from '@/components/equityRisk/widgets/Widget.tsx';
import type { WidgetDropdownChoice } from '@/components/equityRisk/widgets/WidgetPanel.tsx';

interface DndWidgetsProps {
  hide: boolean;
  selectedWidgetDefs: WidgetDef<WidgetParams>[];
  webSocketConnection: WebSocketConnection;
  onMenuClick: (widgetId: string, item: WidgetDropdownChoice) => void;
  onSelectedWidgetDefsUpdate: (nextSelectedWidgets: WidgetDef<any>[]) => void;
  fullscreen?: boolean;
}

export function DndWidgets(props: DndWidgetsProps) {
  const { selectedWidgetDefs, onSelectedWidgetDefsUpdate, fullscreen } = props;

  const [widgetsRef, currentSelectedWidgets, setCurrentSelectedWidgets] = useDragAndDrop<
    HTMLUListElement,
    WidgetDef<WidgetParams>
  >(selectedWidgetDefs, {
    group: 'currentSelectedWidgets',
    dragHandle: '.widget-draghandle',
    plugins: [animations()],
    onDragend: ({ values }) => onSelectedWidgetDefsUpdate(values as WidgetDef<WidgetParams>[]),
    draggingClass: 'dnd-grabzone',
  });

  useEffect(() => {
    setCurrentSelectedWidgets(selectedWidgetDefs);
  }, [selectedWidgetDefs, setCurrentSelectedWidgets]);

  const dndWidgetsClassNames = classNames(
    'd-flex',
    'gap-3',
    'list-unstyled',
    'p-3',
    'overflow-x-scroll',
    {
      'flex-wrap': fullscreen,
    },
  );
  return (
    <ul className={dndWidgetsClassNames} ref={widgetsRef}>
      {currentSelectedWidgets.map(dndWidget => {
        return (
          <li
            id={dndWidget.id}
            key={dndWidget.id}
            onDragEnd={_ => removeZIndexOnDragEnd(dndWidget.id)}
          >
            <Resizable
              className="widget border border-2 border-primary"
              size={dndWidget.size}
              onResizeStop={(_0, _1, _2, delta) => {
                const nextSelectedWidgetDefs = currentSelectedWidgets.map(current => {
                  if (current.id !== dndWidget.id) {
                    return current;
                  }
                  return {
                    ...current,
                    size: {
                      width: dndWidget.size.width + delta.width,
                      height: dndWidget.size.height + delta.height,
                    },
                  };
                });
                // Optimistically updating the local currentSelectedWidgets ( fixes the visual glitch of dimension update )
                setCurrentSelectedWidgets(nextSelectedWidgetDefs);
                onSelectedWidgetDefsUpdate(nextSelectedWidgetDefs);
              }}
            >
              <Widget
                webSocketConnection={props.webSocketConnection}
                onMenuClick={props.onMenuClick}
                widgetDef={dndWidget}
              />
            </Resizable>
          </li>
        );
      })}
    </ul>
  );
}

// https://github.com/formkit/drag-and-drop/issues/100
function removeZIndexOnDragEnd(id: string) {
  const element = document.getElementById(id);
  if (isDefined(element)) {
    element.style.zIndex = 'auto';
  }
}
