import { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import type {
  BookPreTradeDealRequest,
  TradeWay,
} from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { ToggleButton } from '@/components/common/bootstrap/ToggleButton.tsx';

const wayValue = ['Buy', 'Sell'] as const;
const defaultBookPreTradeDealRequest: BookPreTradeDealRequest = {
  underlying: '',
  way: 'Buy',
  portfolio: '',
  productQuantity: '' as unknown as number,
  productPrice: '' as unknown as number,
};

export const CreateAdjustmentModal = NiceModal.create(() => {
  const [bookPreTradeDealRequest, setBookPreTradeDealRequest] = useState<BookPreTradeDealRequest>(
    defaultBookPreTradeDealRequest,
  );
  const modal = useModal();

  function onConfirm() {
    modal.resolve(bookPreTradeDealRequest);
  }

  return (
    <BootstrapModal
      titleId={'Adjustment.EditModalTitle'}
      footer={
        <CancelConfirmFooter
          onConfirm={onConfirm}
          confirmButtonProps={{
            component: 'Add',
          }}
        />
      }
    >
      <>
        <div className="mb-3">
          <label htmlFor="Underlying" className="text-secondary form-label">
            Underlying
          </label>
          <input
            onChange={e =>
              setBookPreTradeDealRequest(previousRequest => ({
                ...previousRequest,
                underlying: e.target.value,
              }))
            }
            className="form-control"
            type="text"
            value={bookPreTradeDealRequest.underlying}
          />
        </div>
        <div className="mb-3">
          <ToggleButton<TradeWay>
            formatId="Adjustment.Way.Radio.Label"
            activeValue={bookPreTradeDealRequest.way}
            values={wayValue}
            onClick={value =>
              setBookPreTradeDealRequest(previousRequest => ({
                ...previousRequest,
                way: value,
              }))
            }
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Portfolio" className="text-secondary form-label">
            Portfolio
          </label>
          <input
            onChange={e =>
              setBookPreTradeDealRequest(previousRequest => ({
                ...previousRequest,
                portfolio: e.target.value,
              }))
            }
            className="form-control"
            type="text"
            value={bookPreTradeDealRequest.portfolio}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Price" className="text-secondary form-label">
            Price
          </label>
          <div>
            <input
              onChange={e =>
                setBookPreTradeDealRequest(previousRequest => ({
                  ...previousRequest,
                  productPrice: Number(e.target.value),
                }))
              }
              className="form-control"
              type="number"
              value={bookPreTradeDealRequest.productPrice}
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="Price" className="text-secondary form-label">
            Quantity
          </label>
          <div>
            <input
              onChange={e =>
                setBookPreTradeDealRequest(previousRequest => ({
                  ...previousRequest,
                  productQuantity: Number(e.target.value),
                }))
              }
              className="form-control"
              type="number"
              value={bookPreTradeDealRequest.productQuantity}
            />
          </div>
        </div>
      </>
    </BootstrapModal>
  );
});
