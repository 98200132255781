import { useAppSelector } from '@/store/hooks.ts';
import type { NumberFormat } from '@/store/slices/prefs/userPreferencesSlice.ts';
import type { WidgetParams } from '@/components/equityRisk/widgets/allWidgets.ts';
import { objectKeys } from '@/utils/libs/entries.ts';

function badgeFormat(
  widgetParam: NonNullable<WidgetParams[keyof WidgetParams]>,
  numberFormat: NumberFormat,
) {
  const { value, type } = widgetParam;
  if (value === undefined) {
    return '';
  }
  if (type === 'select') {
    return value;
  }

  const formatter = Intl.NumberFormat(numberFormat, {
    maximumFractionDigits: type === 'percentage' ? 2 : 0,
    minimumFractionDigits: type === 'percentage' ? 2 : 0,
  });
  if (type === 'percentage') {
    return formatter.format(value * 100) + '%';
  } else {
    return formatter.format(value);
  }
}
interface WidgetBadgesProps {
  params: WidgetParams;
}
export function WidgetBadges(widgetBadgesProps: WidgetBadgesProps) {
  const { params } = widgetBadgesProps;
  const numberFormat = useAppSelector(state => state.userPreferences.numberFormat);
  const paramsKeys = objectKeys(params);

  return (
    <div>
      <div className="d-flex flex-wrap" style={{ columnGap: 10 }}>
        {paramsKeys.slice(0, 2).map(key => {
          const widgetParamKey = params[key];
          return (
            <span className="badge badge-discreet-info" key={key}>
              {widgetParamKey?.label} : {badgeFormat(widgetParamKey!, numberFormat)}
            </span>
          );
        })}
        {paramsKeys.length > 2 ? (
          <span className="badge badge-discreet-info">+ {paramsKeys.length - 2}</span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
