import { isString } from '@sgme/fp';

import type { RmmColumNames } from '@/store/api/rulesApi/rulesModels.ts';
import type { AnalyticalStructure } from '@/store/slices/queryCache/queryCacheSlice.ts';

type EntityData = Pick<RmmColumNames, 'entityName' | 'entityType'>;
export function isRuleLineVisibleGetter(
  analyticalStructure: AnalyticalStructure[],
): (entityData: EntityData) => boolean {
  const gops = new Set<string>();
  const profitCenters = new Set<string>();
  const portfolios = new Set<string>();

  for (const analyticalStructureRow of analyticalStructure) {
    gops.add(analyticalStructureRow.GOP);
    profitCenters.add(analyticalStructureRow.ProfitCenter);
    portfolios.add(analyticalStructureRow.PortFolio);
  }

  return entityData => {
    // must check with typeguard on category instead of entityType null checking
    if (entityData.entityType == null) {
      return true;
    }

    if (!isString(entityData.entityName)) {
      return false;
    }

    switch (entityData.entityType) {
      case 'eliot_center':
        return gops.has(entityData.entityName);
      case 'profit_center':
        return profitCenters.has(entityData.entityName);
      case 'eliot_portfolio':
        return portfolios.has(entityData.entityName);
      default:
        return false;
    }
  };
}
