import { useModal } from '@ebay/nice-modal-react';

import { useBookPreTradeDealMutation } from '@/store/api/adjustmentsApi/adjustmentApi';
import type { BookPreTradeDealRequest } from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { useAppDispatch } from '@/store/hooks.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { CreateAdjustmentModal } from '@/components/adjustments/CreateAdjustmentModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { logger } from '@/utils/libs/logger.ts';

export function CreateAdjustmentModalButton() {
  const createAdjustmentModal = useModal(CreateAdjustmentModal);
  const [bookPreTrade] = useBookPreTradeDealMutation();
  const dispatch = useAppDispatch();

  async function openAdjustmentModal() {
    const request = (await createAdjustmentModal.show()) as BookPreTradeDealRequest;
    try {
      await bookPreTrade(request).unwrap().catch();
    } catch (e) {
      logger.logError(`Fail to book deal  {error_s}`, JSON.stringify(e));
      dispatch(addErrorToastThunk('Error trying to book deal'));
    }
  }

  return (
    <Button onClick={openAdjustmentModal} className="btn-icon-end btn-lg text-nowrap">
      Add new <em className="icon">add</em>
    </Button>
  );
}
