import { useCallback, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import type { WidgetDef } from '@/store/slices/widget/widgetTypes.ts';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { type WidgetParams } from '@/components/equityRisk/widgets/allWidgets.ts';
import { objectKeys } from '@/utils/libs/entries.ts';

type EditWidgetModalProps = {
  onWidgetDefEdit: (widgetDef: WidgetDef<WidgetParams>) => void;
  widgetDef: WidgetDef<WidgetParams>;
};

type EditWidgetProps = {
  widgetDefParamKey: keyof WidgetParams;
  widgetDef: WidgetDef<WidgetParams>;
  onValueChange: (value: string | number) => void;
};

function EditWidgetParam(editWidgetProps: EditWidgetProps) {
  const { widgetDef, onValueChange, widgetDefParamKey } = editWidgetProps;
  const widgetParamData = widgetDef.params[widgetDefParamKey];
  const format = widgetParamData?.type;
  const allowedValues = widgetParamData?.type === 'select' ? widgetParamData.allowedValues : [];

  return (
    <div className="form-group-deprecated" key={widgetDefParamKey}>
      <label htmlFor={widgetDefParamKey} className="form-label">
        {widgetParamData?.label}
      </label>
      {format === 'select' ? (
        <Select<string | number>
          id={widgetDefParamKey}
          itemsAsObjects={allowedValues}
          selectedItem={widgetParamData?.value}
          onChange={value => onValueChange(value)}
        />
      ) : (
        <input
          type={format === 'percentage' ? 'number' : 'number'}
          className="form-control"
          id={widgetDefParamKey}
          step={format === 'percentage' ? '0.01' : '1'}
          value={widgetParamData?.value}
          onChange={event => {
            const value = event.currentTarget.value;
            const parsedValue = parseFloat(value);
            onValueChange(parsedValue);
          }}
        />
      )}
    </div>
  );
}

export const EditWidgetModal = NiceModal.create<EditWidgetModalProps>(
  function EditWidgetModalBuilder(editWidgetProps: EditWidgetModalProps) {
    const { onWidgetDefEdit, widgetDef } = editWidgetProps;
    const [currentWidgetDef, setCurrentWidgetDef] = useState(widgetDef);

    const onValueChange = useCallback(
      (value: string | number, widgetParamKey: keyof WidgetParams) => {
        setCurrentWidgetDef(current => ({
          ...current,
          params: {
            ...current.params,
            [widgetParamKey]: {
              ...current.params[widgetParamKey],
              value,
            },
          },
        }));
      },
      [],
    );

    return (
      <BootstrapModal
        style={{ minHeight: '30em', minWidth: '30em' }}
        titleId={`Widget.Edit.Title`}
        titleValues={{ widgetTitle: widgetDef.title }}
        footer={<CancelConfirmFooter onConfirm={() => onWidgetDefEdit(currentWidgetDef)} />}
      >
        <form>
          <div>
            <div className="form-group-deprecated" key={widgetDef.id}>
              <label htmlFor={'name'} className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id={widgetDef.id}
                value={currentWidgetDef.name}
                onChange={event => {
                  const value = event.currentTarget.value;
                  setCurrentWidgetDef(prevState => ({ ...prevState, name: value }));
                }}
              />
            </div>
            {objectKeys(currentWidgetDef.params).map(widgetConfigParamKey => (
              <EditWidgetParam
                key={widgetConfigParamKey}
                widgetDefParamKey={widgetConfigParamKey}
                widgetDef={currentWidgetDef}
                onValueChange={value => {
                  onValueChange(value, widgetConfigParamKey);
                }}
              />
            ))}
          </div>
        </form>
      </BootstrapModal>
    );
  },
);
