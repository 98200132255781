import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { z } from 'zod';

import { validateAndLog } from '@/store/slices/prefs/validateAndLog.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import type { AppState } from '@/store/store.ts';
import { loadUserSessionService } from '@/web/session/sessionApi.ts';

export const numberFormatValues = ['en', 'fr'] as const;
export const negativeNumberFormatValues = ['-10', '(10)'] as const;

export const userPrefSchema = z.object({
  filterKind: z.union([z.literal('IMPACTING'), z.literal('NON_IMPACTING')]).default('IMPACTING'),
  hedgerOrderDefaultTime: z
    .union([z.literal('RELATIVE'), z.literal('ABSOLUTE')])
    .default('ABSOLUTE'),
  customHierarchiesLabels: z
    .record(
      z.union([
        z.literal('DeskCustomAxis1'),
        z.literal('DeskCustomAxis2'),
        z.literal('DeskCustomAxis3'),
        z.literal('DeskCustomAxis4'),
        z.literal('DeskCustomAxis5'),
      ]),
      z.string(),
    )
    .default({}),
  numberFormat: z.enum(numberFormatValues).default('en'),
  currencyValue: z.union([z.literal('USD'), z.literal('EUR')]).default('EUR'),
  negativeNumberFormat: z.enum(negativeNumberFormatValues).default('-10'),
  flashCell: z.boolean().default(false),
  monitorExecutionPlan: z.boolean().default(false),
  monitorExecutionTiming: z.boolean().default(false),
  topPanelWidgetLimit: z.number().optional(),
});

export type UserPreferences = z.infer<typeof userPrefSchema>;

export type FilterKind = z.infer<typeof userPrefSchema.shape.filterKind>;

export type NegativeNumberFormat = z.infer<typeof userPrefSchema.shape.negativeNumberFormat>;
export type NumberFormat = z.infer<typeof userPrefSchema.shape.numberFormat>;
export type CustomHierarchiesLabels = z.infer<typeof userPrefSchema.shape.customHierarchiesLabels>;

const initialState: UserPreferences = {
  filterKind: 'IMPACTING',
  hedgerOrderDefaultTime: 'ABSOLUTE',
  customHierarchiesLabels: {},
  numberFormat: 'en',
  currencyValue: 'EUR',
  negativeNumberFormat: '-10',
  flashCell: false,
  monitorExecutionPlan: false,
  monitorExecutionTiming: false,
  topPanelWidgetLimit: 3,
};

export function validateUserPreferences(data: unknown): UserPreferences | undefined {
  return validateAndLog('UserPreferences', userPrefSchema, data);
}

export const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState: initialState,
  reducers: {
    setPref: (state, action: PayloadAction<Partial<UserPreferences>>) => {
      return { ...state, ...action.payload };
    },
  },
  selectors: {
    contextValues(state) {
      const { monitorExecutionTiming, currencyValue, monitorExecutionPlan } = state;

      const contextValues: Record<string, string> = {
        displayCurrency: currencyValue,
      };

      if (monitorExecutionPlan) {
        contextValues['monitoring.executionPlanPrint'] = 'true';
      }
      if (monitorExecutionTiming) {
        contextValues['monitoring.executionTimingPrint'] = 'true';
      }
      return contextValues;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserPreferences.fulfilled, (_, action) => {
      if (action.payload !== undefined) {
        return action.payload;
      }
    });
  },
});

export const fetchUserPreferences = createAsyncThunk<
  UserPreferences | undefined,
  void,
  { state: AppState }
>('userPreferences/fetchUserPreferences', async (_, { getState }) => {
  const user = userSlice.selectors.user(getState());
  return loadUserSessionService(user.email, 'userPreferences').then(validateUserPreferences);
});
