import { configureStore, type ThunkAction, type UnknownAction } from '@reduxjs/toolkit';

import { adjustmentApi } from '@/store/api/adjustmentsApi/adjustmentApi.ts';
import { greatServerApi } from '@/store/api/greatServerApi/greatServerApi.ts';
import { hedgerApi } from '@/store/api/hedgerApi/hedgerApi.ts';
import { rulesApi } from '@/store/api/rulesApi/rulesApi.ts';
import { listenerMiddleware } from '@/store/listenerMiddleware.ts';
import { colorsRulesSlice } from '@/store/slices/colorRules/colorsRulesSlice.ts';
import { draftQuerySlice } from '@/store/slices/draftQuery/draftQuerySlice.ts';
import { equityRiskSlice } from '@/store/slices/prefs/equityRiskSlice.ts';
import { hedgerSlice } from '@/store/slices/prefs/hedgerSlice.ts';
import { perimeterPresetsSlice } from '@/store/slices/prefs/perimeterPresetsSlice.ts';
import { userPreferencesSlice } from '@/store/slices/prefs/userPreferencesSlice.ts';
import { visualizationPresetsSlice } from '@/store/slices/prefs/visualizationPresetsSlice.ts';
import { querySlice } from '@/store/slices/query/querySlice.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { uiSlice } from '@/store/slices/ui/uiSlice.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { widgetsSlice } from '@/store/slices/widget/widgetsSlice.ts';

export const reducer = {
  ui: uiSlice.reducer,
  query: querySlice.reducer,
  draftQuery: draftQuerySlice.reducer,
  perimeterPresets: perimeterPresetsSlice.reducer,
  userPreferences: userPreferencesSlice.reducer,
  visualizationPresets: visualizationPresetsSlice.reducer,
  hedger: hedgerSlice.reducer,
  equityRisk: equityRiskSlice.reducer,
  widgets: widgetsSlice.reducer,
  user: userSlice.reducer,
  colorRules: colorsRulesSlice.reducer,
  queryCache: queryCacheSlice.reducer,
  [hedgerApi.reducerPath]: hedgerApi.reducer,
  [rulesApi.reducerPath]: rulesApi.reducer,
  [adjustmentApi.reducerPath]: adjustmentApi.reducer,
  [greatServerApi.reducerPath]: greatServerApi.reducer,
};
export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(
        hedgerApi.middleware,
        rulesApi.middleware,
        adjustmentApi.middleware,
        greatServerApi.middleware,
      )
      .prepend(listenerMiddleware.middleware),
});

export type AppStore = typeof store;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, AppState, any, UnknownAction>;
