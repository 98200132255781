import { useRef } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import {
  colorsRulesSlice,
  replaceRulesThunk,
  rollbackRuleThunk,
  stopEditAndSaveRuleThunk,
  validateColorRules,
  type ColorRule,
} from '@/store/slices/colorRules/colorsRulesSlice.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { ColorRuleEdit } from '@/components/prefs/tabs/ColorRules/ColorRuleEdit.tsx';
import { ColorRules } from '@/components/prefs/tabs/ColorRules/ColorRules.tsx';
import { downloadBlob } from '@/utils/downloadBlob.ts';
import { parseJson, stringifyJson } from '@/utils/json.ts';

export function ColorCodingTab() {
  const colorRules = useAppSelector(state => state.colorRules.rules);
  const editionState = useAppSelector(state => state.colorRules.editionState);
  const dispatch = useAppDispatch();

  function onCreate() {
    dispatch(colorsRulesSlice.actions.addRule());
  }

  async function importFiles(file: File) {
    const rules = validateColorRules(parseJson(await file.text()));
    if (rules.length === 0) {
      dispatch(addErrorToastThunk('Invalid rules file'));
    } else {
      dispatch(replaceRulesThunk(rules));
    }
  }

  function onExport() {
    downloadJson('rules.json', colorRules);
  }

  if (editionState !== undefined) {
    const onCancel = () => dispatch(rollbackRuleThunk());
    const onClose = () => dispatch(stopEditAndSaveRuleThunk());

    return <ColorRuleEdit editIndex={editionState.index} onClose={onClose} onCancel={onCancel} />;
  }
  return (
    <>
      <div className="btn-group">
        <UploadButton onUpload={importFiles} />
        <Button className="btn-icon-start my-3" onClick={onExport}>
          <em className="icon">download</em>
          Export
        </Button>
        <Button className="btn-icon-start my-3" onClick={onCreate}>
          <em className="icon">add</em>
          Create
        </Button>
      </div>
      <ColorRules colorRules={colorRules} />
    </>
  );
}

function downloadJson(filename: string, colorRules: ColorRule[]) {
  const blob = new Blob([stringifyJson(colorRules)], {
    type: 'application/json',
  });
  downloadBlob(filename, blob);
}

function UploadButton({ onUpload }: { onUpload: (file: File) => void }) {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  function onButtonClick() {
    uploadInputRef.current?.click();
  }
  function onInputChange(files: FileList | null) {
    if (files !== null && files.length > 0) {
      onUpload(files[0]);
    }
  }
  return (
    <>
      <input
        ref={uploadInputRef}
        onChange={event => onInputChange(event.target.files)}
        type="file"
        accept="application/json"
        className="d-none"
      />
      <Button className="btn-icon-start my-3" onClick={onButtonClick}>
        <em className="icon">upload</em>
        Import
      </Button>
    </>
  );
}
