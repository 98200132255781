import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';

import type { BlotterDeal } from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { NoRows } from '@/components/common/utils/NoRowsOverlay.tsx';

interface AdjustmentsGridProps {
  pretradeDeals: BlotterDeal[];
  colDefs: ColDef<BlotterDeal>[];
}
export function AdjustmentsGrid(props: AdjustmentsGridProps) {
  return (
    <AgGridReact<BlotterDeal>
      className="ag-theme-alpine ag-theme-era"
      rowData={props.pretradeDeals}
      modules={[ClientSideRowModelModule, RichSelectModule]}
      noRowsOverlayComponent={NoRows}
      gridOptions={{
        suppressMenuHide: false,
      }}
      columnDefs={props.colDefs}
    />
  );
}
