import { assertUnreachable } from '@sgme/fp';

import type { ExclusionMode } from '@/store/slices/query/querySlice.ts';


export function getExclusionClause(exclusionMode: ExclusionMode): string {
  switch (exclusionMode) {
    case 'applied':
      return '[IsExcluded].[FALSE]';
    case 'onlyExcluded':
      return '[IsExcluded].[TRUE]';
    case 'notApplied':
      return '[IsExcluded].[AllMember]';
    default:
      assertUnreachable(exclusionMode, 'Exclusion mode not handled');
  }
}