import { useCallback } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import { useAppSelector } from '@/store/hooks.ts';
import { draftQuerySlice } from '@/store/slices/draftQuery/draftQuerySlice.ts';
import { store } from '@/store/store.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { MessageTooltip } from '@/components/common/bootstrap/MessageTooltip.tsx';

export function PauseButton(): JSX.Element {
  const { trackEvent } = useMatomo();
  const liveUpdates = useAppSelector(state => state.draftQuery.liveUpdates);
  const dispatch = store.dispatch;

  const onClickHandler = useCallback(() => {
    trackEvent({
      category: 'Equity risk grid',
      action: `${liveUpdates ? 'Paused' : 'Resume'} live updates`,
    });
    dispatch(draftQuerySlice.actions.setLiveUpdates(!liveUpdates));
  }, [dispatch, liveUpdates, trackEvent]);

  return (
    <div className="d-flex flex-column align-self-end">
      <MessageTooltip messageId="UpdateButtonController.Tooltip">
        <Button
          variant={liveUpdates ? 'default' : 'info'}
          className={liveUpdates ? 'btn-default' : 'btn-discreet-info'}
          size="md"
          onClick={onClickHandler}
        >
          <em className="icon icon-sm">{liveUpdates ? 'pause' : 'play_arrow'}</em>
        </Button>
      </MessageTooltip>
    </div>
  );
}
