import { objectKeys } from '@/utils/libs/entries.ts';

export const rulesModels = {
  RISK_PROFILE: [
    {
      id: 6,
      columnName: 'riskProfile',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 7,
      columnName: 'doDemeter',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 8,
      columnName: 'doCpm',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 9,
      columnName: 'doSecondary',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 10,
      columnName: 'doAdr',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 11,
      columnName: 'doFund',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 12,
      columnName: 'doDerivative',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 13,
      columnName: 'doFictive',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 14,
      columnName: 'doIndex',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 15,
      columnName: 'doBasket',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
  ],
  EXCLUSION: [
    {
      id: 86,
      columnName: 'exclude',
      columnType: 'BOOLEAN',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 87,
      columnName: 'entityType',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 88,
      columnName: 'entityName',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 89,
      columnName: 'underlyingType',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 90,
      columnName: 'underlyingName',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 91,
      columnName: 'iddef',
      columnType: 'LONG',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
  ],
  UNDERLYING_PROJECTION: [
    {
      id: 47,
      columnName: 'entityType',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 48,
      columnName: 'entityName',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 50,
      columnName: 'underlyingType',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 49,
      columnName: 'underlyingName',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 52,
      columnName: 'toUnderlyingType',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: true,
    },
    {
      id: 51,
      columnName: 'toUnderlyingName',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: true,
    },
    {
      id: 53,
      columnName: 'weight',
      columnType: 'DOUBLE',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
  ],
  AGGREGATION_ANALYTICAL_STRUCTURE: [
    {
      id: 82,
      columnName: 'entityType',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 83,
      columnName: 'entityName',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 84,
      columnName: 'level1',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: false,
    },
    {
      id: 85,
      columnName: 'level2',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: false,
    },
    {
      id: 86,
      columnName: 'level3',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: false,
    },
  ],
  AGGREGATION_UNDERLYING: [
    {
      id: 161,
      columnName: 'entityType',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: true,
    },
    {
      id: 160,
      columnName: 'entityName',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: true,
    },
    {
      id: 162,
      columnName: 'underlyingType',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: true,
    },
    {
      id: 163,
      columnName: 'underlyingName',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: true,
    },
    {
      id: 164,
      columnName: 'deskCustomAxis1',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 165,
      columnName: 'deskCustomAxis2',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 166,
      columnName: 'deskCustomAxis3',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 167,
      columnName: 'deskCustomAxis4',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
    {
      id: 168,
      columnName: 'deskCustomAxis5',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
  ],
  DECOMPOSITION: [
    {
      id: 67,
      columnName: 'entityType',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: true,
    },
    {
      id: 68,
      columnName: 'entityName',
      columnType: 'STRING',
      columnControl: null,
      isInput: true,
      isKey: true,
    },
    {
      id: 70,
      columnName: 'underlyingType',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: true,
    },
    {
      id: 71,
      columnName: 'underlyingName',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: true,
    },
    {
      id: 69,
      columnName: 'riskProfile',
      columnType: 'STRING',
      columnControl: null,
      isInput: false,
      isKey: false,
    },
  ],
} as const;

export const rulesCategories = objectKeys(rulesModels);

export type RmmColumnDefinitions = typeof rulesModels;
export type RuleCategory = keyof RmmColumnDefinitions;

export type RmmColumnName<T extends RuleCategory = RuleCategory> =
  RmmColumnDefinitions[T][number]['columnName'];

export type RmmColumNames<T extends RuleCategory = RuleCategory> = Record<
  RmmColumnName<T>,
  string | number | boolean
>;

export const underlyingTypes = ['Basket', 'Cash', 'Fund', 'Forex', 'Index', 'Share'] as const;
export const rulesEntityTypes = ['eliot_portfolio', 'eliot_center', 'profit_center'] as const;

export type RuleEntityType = (typeof rulesEntityTypes)[number];

export function isEntityType(value: unknown): value is RuleEntityType {
  return rulesEntityTypes.findIndex(elem => value === elem) >= 0;
}
interface ColumnDefinition {
  id: number;
  columnName: string;
  columnType: 'STRING' | 'BOOLEAN' | 'LONG';
  columnControl: null;
  isInput: boolean;
  isKey: boolean;
}

export type RuleGridRow = {
  id: number;
  line: RmmColumNames;
};

export interface RulesModel {
  id: number;
  version: number;
  mnemonic: string;
  description: string;
  context: string;
  metric: string;
  category: RuleCategory;
  validityStartDate: string;
  validityEndDate: string | null;
  columnsDefinition: ColumnDefinition[];
  grid: RuleGridRow[];
  userName: string;
}
