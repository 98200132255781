import { ScenarioFrame } from './components/ScenarioFrame.tsx';
import { useModal } from '@ebay/nice-modal-react';
import { getRouteApi, useRouter } from '@tanstack/react-router';

import {
  useDeleteScenarioMutation,
  useUpdateScenarioMutation,
  type Scenario,
} from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { DeleteModal } from '@/components/hedger/manage/common/DeleteModal.tsx';
import { useScenarioQueryData } from '@/components/hedger/manage/scenarios/components/useScenarioQueryData.tsx';
import { safeParseNumber } from '@/utils/libs/safeParseNumber.ts';

const hedgerEditScenarioRouteApi = getRouteApi('/hedger/scenarios/edit/$scenarioId');

export function EditScenario() {
  const { scenarioId: scenarioIdParam } = hedgerEditScenarioRouteApi.useParams();

  const queryData = useScenarioQueryData();

  const [updateScenarioTrigger, { isLoading: isUpdateLoading, originalArgs, isError }] =
    useUpdateScenarioMutation();
  const [deleteScenarioTrigger, { isLoading: isDeleteLoading }] = useDeleteScenarioMutation();

  const teams = useAppSelector(userSlice.selectors.teams);
  const deleteModal = useModal(DeleteModal);
  const { navigate } = useRouter();

  if (isUpdateLoading || isDeleteLoading) {
    return <Loader />;
  }

  if (queryData.state === 'uninitialized') {
    return queryData.Component;
  }

  function closeScenario() {
    navigate({ to: '/hedger/scenarios' });
  }

  function onDeleteClick() {
    deleteModal.show({
      onConfirmClick: () => deleteScenario(scenarioId),
      type: 'Scenario',
    });
  }

  function updateScenario(scenario: Scenario) {
    updateScenarioTrigger(scenario)
      .unwrap()
      .then(closeScenario)
      .catch(error => handleHedgerError('Error while saving scenario', error));
  }

  function deleteScenario(scenarioId: number) {
    deleteScenarioTrigger({ scenarioId })
      .unwrap()
      .then(closeScenario)
      .catch(error => handleHedgerError('Error while deleting scenario', error));
  }

  const scenario = queryData.scenarios.find(
    scenario => scenario.scenarioId === safeParseNumber(scenarioIdParam),
  );

  if (scenario === undefined) {
    return (
      <ErrorPanel>
        No scenario of id <span className="fw-semibold">{scenarioIdParam}</span>.
      </ErrorPanel>
    );
  }

  const currentEditScenario = isError && originalArgs != null ? originalArgs : scenario;
  const { scenarioId, ...scenarioData } = currentEditScenario;

  return (
    <ScenarioFrame
      mode="edit"
      markets={queryData.markets}
      recipients={queryData.recipients}
      scenarioData={scenarioData}
      teams={teams}
      onDelete={onDeleteClick}
      onSave={s => updateScenario({ ...s, scenarioId })}
      onCancel={closeScenario}
    />
  );
}
