import { useNavigate, useSearch } from '@tanstack/react-router';

import {
  useCreateScenarioMutation,
  type Scenario,
} from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { Loader } from '@/components/common/bootstrap/Loader';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { ScenarioFrame } from '@/components/hedger/manage/scenarios/components/ScenarioFrame.tsx';
import { useScenarioQueryData } from '@/components/hedger/manage/scenarios/components/useScenarioQueryData.tsx';
import type { StrictOmit } from '@/utils/libs/strictTypes.ts';
import { getUserInfo } from '@/utils/sgwt/userPermission.ts';

export function CreateScenario() {
  const [createScenarioTrigger, { isLoading, isError, originalArgs }] = useCreateScenarioMutation();

  const navigate = useNavigate();
  const teams = useAppSelector(userSlice.selectors.teams);
  const { cloneId } = useSearch({ from: '/hedger/scenarios/create' });

  const queryData = useScenarioQueryData();

  function closeScenario() {
    navigate({ to: '/hedger/scenarios' });
  }

  function createScenario(scenario: Omit<Scenario, 'scenarioId'>): void {
    createScenarioTrigger(scenario)
      .unwrap()
      .then(closeScenario)
      .catch(error => handleHedgerError('Error while creating scenario', error));
  }

  if (queryData.state === 'uninitialized') {
    return queryData.Component;
  }

  if (isLoading) {
    return <Loader />;
  }

  const scenarioToClone = queryData.scenarios.find(scenario => scenario.scenarioId === cloneId);

  if (cloneId !== undefined && scenarioToClone === undefined) {
    return (
      <ErrorPanel>
        No scenario of id <span className="fw-semibold">{cloneId}</span> .
      </ErrorPanel>
    );
  }

  const scenarioData =
    isError && originalArgs !== undefined ? originalArgs : getScenarioInit(teams, scenarioToClone);

  return (
    <ScenarioFrame
      mode="create"
      markets={queryData.markets}
      recipients={queryData.recipients}
      onSave={createScenario}
      onCancel={closeScenario}
      scenarioData={scenarioData}
      teams={teams}
    />
  );
}

function getScenarioInit(
  teams: string[],
  scenarioInit?: Scenario,
): StrictOmit<Scenario, 'scenarioId'> {
  if (scenarioInit === undefined) {
    return {
      // FIXME: use user in state
      userId: getUserInfo()?.sesame_id ?? '',
      team: teams[0] ?? '',
      deactivatedProductTypes: ['UNKNOWN', 'INDEX', 'BASKET'],
      groupedGopsRules: [],
      ordersMetricsCurrency: 'USD',
      name: '',
      deactivatedTickers: [],
      outputOptions: [],
      axisConfigs: {},
      hedgeAxis: 'MarketCode',
    };
  }
  return {
    ...scenarioInit,
    userId: getUserInfo()?.sesame_id ?? '',
    team: (teams.includes(scenarioInit.team) ? scenarioInit.team : teams[0]) ?? '',
    name: '',
  };
}
