import { memo } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { CellValueChangedEvent, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { useModal } from '@ebay/nice-modal-react';

import type { TradingToolTemplate } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { AdditionalField } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { EditTableModal } from '@/components/hedger/manage/recipients/details/EditTableModal.tsx';
import { MultiEditTableModal } from '@/components/hedger/manage/recipients/details/MultiEditTableModal.tsx';
import { getRecipientAdditionalFieldsColDef } from '@/components/hedger/manage/recipients/getRecipientColDef.tsx';

export const EditRecipientGrid = memo(EditRecipientAdditionalFieldsGrid);

export interface EditAdditionalFieldsGrid {
  additionalFields: Record<string, any>;
  updateAdditionalField: (key: string, additionalField: AdditionalField) => void;
  tradingToolTemplate: TradingToolTemplate;
}

function EditRecipientAdditionalFieldsGrid(props: EditAdditionalFieldsGrid): JSX.Element {
  const { additionalFields, tradingToolTemplate, updateAdditionalField } = props;

  function onGridReady(event: GridReadyEvent<GridApi>) {
    event.api.sizeColumnsToFit({ defaultMinWidth: 170 });
  }

  const editTableModal = useModal(EditTableModal);
  const multiEditTableModal = useModal(MultiEditTableModal);

  const colDefs = getRecipientAdditionalFieldsColDef(
    tradingToolTemplate.additionalFields ?? [],
    params => editTableModal.show(params),
    params => multiEditTableModal.show(params),
    updateAdditionalField,
    'Edit',
  );
  const rowData: AdditionalField[] = [
    {
      ...additionalFields,
    },
  ];

  function onCellValueChanged(event: CellValueChangedEvent) {
    const colId = event.colDef.field;
    if (colId === undefined) {
      return;
    }
    updateAdditionalField(colId, event.newValue);
  }

  return (
    <div
      className="d-flex flex-column flex-grow-1 ag-theme-alpine ag-theme-era ag-theme-era-oneline"
      style={{ height: '5rem' }}
    >
      <AgGridReact
        modules={[ClientSideRowModelModule, RangeSelectionModule, RichSelectModule]}
        singleClickEdit
        onCellValueChanged={onCellValueChanged}
        enableRangeSelection
        rowData={rowData}
        columnDefs={colDefs}
        onGridReady={onGridReady}
      />
    </div>
  );
}
