import type { Measure } from '@/core/measures.ts';
import type { CubeMode } from '@/types/AppConfig.ts';

export function mdxMeasuresStatement(measures: Measure[], cubeMode: CubeMode) {
  return measures.map(measure => getMeasureMdxQueryPart(measure, cubeMode)).join(',');
}

export function getMeasureMdxQueryPart(measure: Measure, cubeMode: CubeMode): string {
  const measureString = `[Measures].${getMeasureString(measure, cubeMode)}`;

  const valuationType = measure.valuationType ?? 'Intraday';
  const valuation =
    valuationType === 'All'
      ? `[ValuationType].[ValuationType].[AllMember]`
      : `[ValuationType].[ValuationType].[AllMember].[${valuationType}]`;

  const dailyOrOpenType = measure.dailyOrOpen ?? 'All';
  const dailyOrOpen =
    dailyOrOpenType === 'All'
      ? `[DailyOrOpen].[DailyOrOpen].[AllMember]`
      : `[DailyOrOpen].[DailyOrOpen].[AllMember].[${dailyOrOpenType}]`;

  return `( 
    ${measureString}, 
    ${valuation},
    ${dailyOrOpen}
  )`;
}

function getMeasureString(m: Measure, cubeMode: CubeMode): string {
  return cubeMode === 'sgCube'
    ? `[Measures].[${m.name.replaceAll('.', '')}${
        m.aggregation !== undefined ? `.${m.aggregation}` : ''
      }]`
    : `[Measures].[${m.name}]`;
}
