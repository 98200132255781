import pick from 'just-pick';
import { asSequence } from 'sequency';

import {
  rulesModels,
  type RmmColumNames,
  type RuleCategory,
  type RulesModel,
} from '@/store/api/rulesApi/rulesModels.ts';
import type { AnalyticalStructure } from '@/store/slices/queryCache/queryCacheSlice.ts';

export type RuleRowData<T extends RuleCategory = RuleCategory> = {
  topLevelRuleId: number;
  metric: string;
  id: number;
} & RmmColumNames<T>;

export class BaseRowDataManager {
  protected ruleIdByMetric: Map<string, { metric: string; ruleId: number }>;
  protected rowData: RuleRowData[] = [];
  protected unallowedRowData: RuleRowData[] = [];
  protected currentAddedId: number = -1;
  protected readonly keyColumns: string[];
  protected duplicatedEntries: Map<string, RuleRowData[]> = new Map();

  constructor(
    protected readonly category: RuleCategory,
    protected rules: RulesModel[],
    protected readonly analyticalStructures: AnalyticalStructure[],
  ) {
    this.ruleIdByMetric = asSequence(rules)
      .map(r => ({ ruleId: r.id, metric: r.metric }))
      .associateBy(r => r.metric);
    this.keyColumns = [
      'metric',
      ...rulesModels[category].filter(c => c.isKey).map(c => c.columnName),
    ];
  }

  getEmptyRow(): RuleRowData {
    const rowId = this.currentAddedId--;
    const rowData: RuleRowData = {
      // @ts-ignore
      topLevelRuleId: null, // not known, depends on metricId
      // @ts-ignore
      metric: null, // not known,
      id: rowId,
    };
    this.initializeRowData(rowData);

    return rowData;
  }

  addEmptyRow(): void {
    const emptyRow = this.getEmptyRow();
    this.rowData.push(emptyRow);
    this.computeDuplicated();
  }

  deleteRow(rowId: string) {
    const rowIndex = this.rowData.findIndex(r => r.id.toString() === rowId);
    this.rowData.splice(rowIndex, 1);
    this.computeDuplicated();
  }

  protected getValue(rowData: RuleRowData, colKey: string): unknown {
    return (rowData as any)[colKey];
  }

  getRowData(): RuleRowData[] {
    return this.rowData;
  }

  computeDuplicated() {
    const rowsByKeys = asSequence(this.rowData).groupBy(row => this.getKey(row));
    this.duplicatedEntries = new Map(
      [...rowsByKeys.entries()].filter(([key, rowDatas]) => {
        return rowDatas.length > 1;
      }),
    );
  }

  isDuplicated(rowData: RuleRowData): string | undefined {
    const key = this.getKey(rowData);
    if (this.duplicatedEntries.has(key)) {
      return key;
    }
  }

  protected getKey(rowData: RuleRowData): string {
    // @ts-ignore
    return JSON.stringify(pick(rowData, this.keyColumns));
  }

  protected initializeRowData(rowData: RuleRowData) {
    for (const column of rulesModels[this.category]) {
      const { columnName } = column;
      if (columnName === 'exclude') {
        rowData[columnName] = true;
        continue;
      }

      rowData[column.columnName] = null as any;
    }
  }
}
