import { Outlet } from '@tanstack/react-router';

import { useAppSelector } from '@/store/hooks.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { RulesSideNav } from '@/components/rules/RulesSideNav.tsx';

function RulesNotAvailable() {
  return (
    <div className="d-flex justify-content-center text-center mt-3">
      <div className="w-25">
        <em className="icon icon-xl text-warning">error</em>
        <h3 className="text-warning">Your perimeter can't be loaded</h3>
        <p className="mt-2 text-secondary">
          We can't retrieve your access rights to display the proper rules applied. Rules screen is
          unavailable.
        </p>
        <p className="text-secondary">Please contact support</p>
      </div>
    </div>
  );
}

export function RulesPage() {
  const isGreatAvailable = useAppSelector(queryCacheSlice.selectors.isStateInitialized);
  if (!isGreatAvailable) {
    return <RulesNotAvailable />;
  }
  return (
    <div className="d-flex h-100">
      <RulesSideNav />
      <div className="d-flex flex-grow-1 p-4">
        <Outlet />
      </div>
    </div>
  );
}
