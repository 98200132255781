import { useCallback, useMemo, useRef, useState } from 'react';
import {
  type CellValueChangedEvent,
  type ColDef,
  type GridApi,
  type ProcessCellForExportParams,
} from '@ag-grid-community/core';
import { getRouteApi } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';
import { z } from 'zod';

import { useUpdateRuleMutation } from '@/store/api/rulesApi/rulesApi.ts';
import {
  isEntityType,
  type RuleCategory,
  type RulesModel,
} from '@/store/api/rulesApi/rulesModels.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import {
  queryCacheSlice,
  type AnalyticalStructure,
} from '@/store/slices/queryCache/queryCacheSlice.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { IfAnyPermission } from '@/components/common/components/IfAnyPermission.tsx';
import { isFetchBaseQueryError } from '@/components/common/utils/isFetchBaseQueryError.ts';
import { getColDefs, refreshCell } from '@/components/rules/getColDefs.tsx';
import { isRuleLineVisibleGetter } from '@/components/rules/isRuleLineVisibleGetter.ts';
import { parseBooleanOrBooleanLike } from '@/components/rules/parsers.ts';
import type { RuleRowData } from '@/components/rules/rowDataManager/BaseRowDataManager.ts';
import { RowDataManager } from '@/components/rules/rowDataManager/RowDataManager.ts';
import { RulesGrid } from '@/components/rules/RulesGrid.tsx';
import { logger } from '@/utils/libs/logger.ts';

const routeApi = getRouteApi('/rules/$category');

export type TableMode = 'view' | 'edit';

function onCellValueChanged(params: CellValueChangedEvent<RuleRowData>) {
  const colId = params.column.getId();
  if (colId === 'entityType') {
    params.node.setDataValue('entityName', null);
  }
}

function processCellFromClipboardGetter(
  params: ProcessCellForExportParams,
  analyticalStructures: AnalyticalStructure[],
) {
  const colId = params.column.getColId();
  const { node, value } = params;
  const entityType = node?.data['entityType'];

  if (node == null) {
    return;
  }
  if (colId === 'entityType' && !isEntityType(value)) {
    return;
  }
  const isRuleLineVisible = isRuleLineVisibleGetter(analyticalStructures)({
    entityName: value,
    entityType,
  });
  if (colId === 'entityName' && (entityType == null || !isRuleLineVisible)) {
    return;
  }

  if (colId === 'exclude') {
    return parseBooleanOrBooleanLike(value);
  }

  return value;
}

export function RulesCategoryComponent() {
  const { rules } = routeApi.useLoaderData();
  const { category } = routeApi.useParams();

  const analyticalStructures = useAppSelector(queryCacheSlice.selectors.analyticalStructureRowData);
  const dispatch = useAppDispatch();
  const gridApi = useRef<GridApi>();

  const [mode, setMode] = useState<TableMode>('view');

  const [updateRule] = useUpdateRuleMutation();

  const defaultColDef = useMemo<ColDef>(
    () => ({
      editable: mode === 'edit',
      // enableCellChangeFlash: true,
      onCellValueChanged: refreshCell,
    }),
    [mode],
  );

  const rowDataManager = useMemo<RowDataManager>(
    () => new RowDataManager(category, rules, analyticalStructures),
    [rules, category, analyticalStructures],
  );

  const metricsValues = useMemo(() => {
    return [...new Set(rules.map(r => r.metric))];
  }, [rules]);

  const handleDelete = useCallback(
    (rowId: string) => {
      rowDataManager.deleteRow(rowId);
      gridApi.current?.setGridOption('rowData', rowDataManager.getRowData());
      gridApi.current?.refreshCells({ columns: ['duplicated'] });
    },
    [rowDataManager, gridApi],
  );

  function handleAddRow() {
    rowDataManager.addEmptyRow();
    gridApi.current?.setGridOption('rowData', rowDataManager.getRowData());
    gridApi.current?.ensureIndexVisible(rowDataManager.getRowData().length - 1);
    gridApi.current?.refreshCells({ columns: ['duplicated'] });
  }

  const colDefs = useMemo<ColDef<RuleRowData>[]>(() => {
    if (rowDataManager === undefined) {
      return [];
    }
    return getColDefs(
      category,
      mode,
      metricsValues,
      rowDataManager,
      handleDelete,
      analyticalStructures,
    );
  }, [category, metricsValues, mode, rowDataManager, handleDelete, analyticalStructures]);

  class ApiError {
    constructor(
      public err: any,
      public rule: RulesModel,
    ) {}
  }

  async function handleSave() {
    const newRules = rowDataManager.toGridModel();
    const responses = await Promise.all(
      newRules.map(rule =>
        updateRule(rule)
          .unwrap()
          .catch(e => new ApiError(e, rule)),
      ),
    );

    const validUpdates: RulesModel[] = [];
    let hasError = false;

    for (const response of responses) {
      if (response instanceof ApiError) {
        hasError = true;
        const errorMessage = getRuleErrorMessage(response.err);
        logger.logError('Error while saving rule {error_s}', errorMessage);
        dispatch(addErrorToastThunk(errorMessage ?? 'Error updating rules'));
        validUpdates.push(response.rule);
      } else {
        validUpdates.push(response);
      }
    }

    rowDataManager.reset(validUpdates);
    if (!hasError) {
      setMode('view');
    }
  }

  function switchToEdit() {
    setMode('edit');
  }

  async function cancelSave() {
    setMode('view');
    rowDataManager.reset();
  }

  return (
    <div className="d-flex flex-column h-100 flex-grow-1">
      <TitleAndTools
        mode={mode}
        category={category}
        onSave={handleSave}
        onCancel={cancelSave}
        onEdit={switchToEdit}
      />
      <RulesGrid<RuleRowData>
        importDataFromRulesGrid={rowDataToAdd => {
          rowDataManager.importRowData(rowDataToAdd);
          gridApi.current?.setGridOption('rowData', rowDataManager.getRowData());
          gridApi.current?.refreshCells();
        }}
        rules={rules}
        category={category}
        metricsValues={metricsValues}
        tooltipShowDelay={100}
        mode={mode}
        onAddRow={handleAddRow}
        onGridReady={({ api }) => (gridApi.current = api)}
        defaultColDef={defaultColDef}
        getRowId={({ data }) => data.id.toString()}
        rowHeight={30}
        undoRedoCellEditing
        undoRedoCellEditingLimit={50}
        columnDefs={colDefs}
        onCellEditingStopped={event => {
          if (event.valueChanged) {
            rowDataManager.computeDuplicated();
            gridApi.current?.refreshCells({ columns: ['duplicated'] });
          }
        }}
        onPasteEnd={() => {
          rowDataManager.computeDuplicated();
          gridApi.current?.refreshCells({ columns: ['duplicated'] });
        }}
        processCellFromClipboard={params =>
          processCellFromClipboardGetter(params, analyticalStructures)
        }
        rowData={rowDataManager.getRowData()}
        onCellValueChanged={onCellValueChanged}
      />
    </div>
  );
}

function TitleAndTools(props: {
  mode: 'view' | 'edit';
  category: RuleCategory;
  onSave: () => Promise<void>;
  onCancel: () => Promise<void>;
  onEdit: () => void;
}) {
  return (
    <div className="d-flex flex-between">
      {props.mode === 'edit' ? (
        <h4 className="text-info">
          Edit - <FormattedMessage id={`Rules.Link.${props.category}`} />
        </h4>
      ) : (
        <h4>
          <FormattedMessage id={`Rules.Link.${props.category}`} />
        </h4>
      )}

      <IfAnyPermission permissions={['editRules', 'devci']}>
        {props.mode === 'edit' ? (
          <div className="d-flex gap-1">
            <Button onClick={props.onCancel}>Cancel</Button>
            <Button variant="info" onClick={props.onSave}>
              Save changes
            </Button>
          </div>
        ) : (
          <div>
            <Button className="btn-icon-end" onClick={props.onEdit}>
              Edit rule
              <em className="icon icon-sm">edit</em>
            </Button>
          </div>
        )}
      </IfAnyPermission>
    </div>
  );
}

const rmmErrorSchema = z.object({
  message: z.string(),
  error: z.string(),
});

function getRuleErrorMessage(error: unknown): string | undefined {
  if (isFetchBaseQueryError(error)) {
    const result = rmmErrorSchema.safeParse(error.data);
    if (result.success) {
      return result.data.message;
    }
  }
  return undefined;
}
