import type { ICellRendererParams } from '@ag-grid-community/core';

import type { AxisConfig } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';

export function DeleteButton({ node, api }: ICellRendererParams<AxisConfig>): JSX.Element {
  function onDeleteClick() {
    if (node.data) {
      api.applyTransaction({ remove: [node.data] });
    }
  }

  return (
    <Button
      className="d-flex p-1 align-content-start "
      flat
      variant="danger"
      onClick={onDeleteClick}
    >
      <i className="icon icon-md icon-">delete_outline</i>
    </Button>
  );
}
