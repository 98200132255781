import { useEffect, useMemo } from 'react';
import { ManagePresetModal } from '../common/modals/ManagePresetModal.tsx';
import { useModal } from '@ebay/nice-modal-react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import deepEqual from 'fast-deep-equal';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import {
  addNewPerimeterPresetToSessionServiceThunk,
  DEFAULT_PERIMETER_PRESET_KEY,
  deletePerimeterPresetFromSessionServiceThunk,
  perimeterPresetsSlice,
  selectActivePerimeterPreset,
  SELECTED_PERIMETER_PRESET_LS_KEY,
} from '@/store/slices/prefs/perimeterPresetsSlice.ts';
import { presetKeyToString, type PresetKey } from '@/store/slices/prefs/presetKey.ts';
import { querySlice } from '@/store/slices/query/querySlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { SavePresetModal } from '@/components/equityRisk/controlPanel/common/modals/SavePresetModal.tsx';
import { getSaveViewChoices } from '@/components/equityRisk/controlPanel/perimeter/getSaveViewChoices.ts';
import { SaveView } from '@/components/equityRisk/controlPanel/SaveView.tsx';
import type { SaveViewChoiceType } from '@/components/equityRisk/controlPanel/saveViewChoices.ts';
import { getAllowedActivePerimeters } from '@/components/gridTable/tools/reducePerimeterKeys.ts';
import { saveToStorage } from '@/utils/storage/storage.ts';

interface PerimeterSelectorProps {
  allPerimeters: string[];
}

export function PerimeterSelector({ allPerimeters }: PerimeterSelectorProps): JSX.Element {
  const dispatch = useAppDispatch();
  const saveModal = useModal(SavePresetModal);
  const { trackEvent } = useMatomo();
  const presetKeys = useAppSelector(perimeterPresetsSlice.selectors.selectPerimeterPresetKeys);
  const activePerimeterPreset = useAppSelector(selectActivePerimeterPreset);
  const activePerimeterPresetKey = useAppSelector(state => state.perimeterPresets.activePresetKey);
  const draftPerimeters = useAppSelector(state => state.perimeterPresets.draftPerimeters);
  const allowedActivePerimeters = useMemo(() => {
    return getAllowedActivePerimeters(activePerimeterPreset?.perimeters ?? [], allPerimeters);
  }, [activePerimeterPreset, allPerimeters]);

  const hasChanged = !deepEqual(draftPerimeters, activePerimeterPreset?.perimeters);

  function onCancelSave() {
    dispatch(perimeterPresetsSlice.actions.resetDraftPerimeterPreset());
    dispatch(querySlice.actions.setPerimeters(allowedActivePerimeters));
  }

  async function onSave(choice: SaveViewChoiceType) {
    function onSavePreset(presetKey: PresetKey) {
      trackEvent({
        category: 'Perimeter preset',
        action: 'Save preset',
        name: JSON.stringify(presetKey),
      });
      dispatch(
        addNewPerimeterPresetToSessionServiceThunk({ ...presetKey, perimeters: draftPerimeters }),
      );
      dispatch(
        perimeterPresetsSlice.actions.setValue({
          activePresetKey: presetKey,
        }),
      );
    }

    if (choice === 'AsNew') {
      await saveModal.show({
        presetKeys,
        onSavePreset,
      });
    } else if (choice === 'AsCurrent') {
      onSavePreset(activePerimeterPresetKey);
    }
  }
  const managePresetModal = useModal(ManagePresetModal);

  function onDeletePreset(team: string, presetNames: string[]) {
    trackEvent({
      category: 'Perimeter preset',
      action: 'Delete preset',
      name: presetNames.join(' '),
    });
    dispatch(deletePerimeterPresetFromSessionServiceThunk(team, presetNames));
  }

  function showManagePreset() {
    return managePresetModal.show({
      onDeletePreset,
      presetKeys: presetKeys,
      defaultPresetKey: DEFAULT_PERIMETER_PRESET_KEY,
      title: 'Perimeter presets',
      titleId: 'PerimeterPreset.Manage.Title',
    });
  }

  useEffect(() => {
    function applyPerimeter() {
      if (activePerimeterPreset === undefined) {
        return;
      }
      saveToStorage(SELECTED_PERIMETER_PRESET_LS_KEY, activePerimeterPresetKey);
      // Only reduced perimeters that are saved AND available on the last update of referentialCache that should be used.
      dispatch(querySlice.actions.setPerimeters(allowedActivePerimeters));
      dispatch(
        perimeterPresetsSlice.actions.setDraftPerimeterPreset(activePerimeterPreset.perimeters),
      );
    }

    applyPerimeter();
  }, [
    activePerimeterPreset,
    activePerimeterPresetKey,
    allPerimeters,
    allowedActivePerimeters,
    dispatch,
  ]);

  function onPerimeterChange(activePresetKey: PresetKey) {
    trackEvent({
      category: 'Perimeter preset',
      action: 'Change preset',
      name: JSON.stringify(activePresetKey),
    });
    dispatch(perimeterPresetsSlice.actions.setValue({ activePresetKey }));
  }

  const choices: SaveViewChoiceType[] = getSaveViewChoices(activePerimeterPreset, allPerimeters);

  return (
    <div className="d-flex">
      <div className="d-flex gap-2 align-items-end">
        <div>
          <label className="form-label" htmlFor="select-preset-key">
            <FormattedMessage id="TopPanel.PerimeterPreset.Label" />
          </label>
          <Select<PresetKey>
            id="select-preset-key"
            itemsAsObjects={presetKeys}
            selectedItem={activePerimeterPresetKey}
            onChange={onPerimeterChange}
            itemToString={key => `${key.name}`}
            itemValue={presetKeyToString}
            groupFn={key => key.team}
          />
        </div>

        <Button icon discreet variant="secondary" title="Save preset" onClick={showManagePreset}>
          <em className="icon">edit</em>
        </Button>
      </div>
      <div className="d-flex gap-2 align-self-end">
        <SaveView
          id="preset-dropdown"
          hidden={!hasChanged}
          onChange={onSave}
          onCancel={onCancelSave}
          saveChoices={choices}
        />
      </div>
    </div>
  );
}
