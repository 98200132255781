import { FormattedMessage } from 'react-intl';

import type { MarketRef } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import {
  Currencies,
  DeactivatedValues,
  HedgeAxes,
  type AxisConfig,
  type CurrencyType,
  type DeactivatedValuesType,
  type HedgeAxis,
  type Scenario,
} from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { BorderItem } from '@/components/common/bootstrap/BorderItem.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { MultiselectDropdown } from '@/components/common/bootstrap/MultiselectDropdown.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { useMethods } from '@/components/common/hooks/useMethods.ts';
import { ShowIf } from '@/components/common/utils/ShowIf.tsx';
import { EditAxisGrid } from '@/components/hedger/manage/scenarios/components/EditAxisGrid.tsx';
import {
  axisConfigToMap,
  useFormatHedgeAxis,
} from '@/components/hedger/manage/scenarios/components/hedgeAxis.ts';
import { objectValues } from '@/utils/libs/entries.ts';

export type ScenarioData = Omit<Scenario, 'scenarioId'>;

interface CreateScenarioFrameProps {
  scenarioData: ScenarioData;
  teams: string[];

  mode: 'edit' | 'create';
  recipients: Recipient[];
  markets: MarketRef[];

  onCancel: () => void;
  onDelete?: () => void;
  onSave: (scenario: ScenarioData) => void;
}

interface State {
  scenarioData: ScenarioData;
  axisConfig: AxisConfig[];
}

function createMethods(state: State) {
  return {
    onScenarioNameChange(name: string): State {
      const scenarioData: ScenarioData = { ...state.scenarioData, name };
      return { ...state, scenarioData };
    },
    onTeamChange(team: string): State {
      const scenarioData: ScenarioData = { ...state.scenarioData, team };
      return { ...state, scenarioData };
    },
    onCurrencyChange(ordersMetricsCurrency: CurrencyType): State {
      const scenarioData: ScenarioData = { ...state.scenarioData, ordersMetricsCurrency };
      return { ...state, scenarioData };
    },
    onProductTypeChange(deactivatedProductTypes: DeactivatedValuesType[]): State {
      const scenarioData: ScenarioData = { ...state.scenarioData, deactivatedProductTypes };
      return { ...state, scenarioData };
    },
    onHedgeAxisChange(hedgeAxis: HedgeAxis): State {
      const scenarioData: ScenarioData = { ...state.scenarioData, hedgeAxis };
      return { ...state, scenarioData, axisConfig: [] };
    },
  } as const;
}

export function ScenarioFrame({
  mode,
  scenarioData,
  teams,

  recipients,
  markets,

  onSave,
  onDelete,
  onCancel,
}: CreateScenarioFrameProps) {
  const initialState: State = {
    scenarioData,
    axisConfig: objectValues(structuredClone(scenarioData.axisConfigs)),
  };
  const [state, stateMethods] = useMethods(createMethods, initialState);
  const formatHedgeAxis = useFormatHedgeAxis();

  async function onSaveClick() {
    const scenario: ScenarioData = {
      ...state.scenarioData,
      axisConfigs: axisConfigToMap(state.axisConfig),
    };
    console.log('save scenario', scenario);
    onSave(scenario);
  }

  return (
    <div
      className="d-flex flex-column mx-auto flex-grow-1 w-75 gap-3 mb-3"
      style={{ minWidth: '60rem' }}
    >
      <div className="d-flex flex-column bg-lvl2 gap-3 p-3 border flex-grow-1 border-info">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="text-info fw-semibold">
            <FormattedMessage
              id={
                mode === 'edit'
                  ? 'Hedger.ScenariosModal.EditScenario.Title'
                  : 'Hedger.ScenariosModal.CreateScenario'
              }
            />
          </h4>
          <div className="d-flex gap-2">
            <ShowIf condition={onDelete !== undefined}>
              <Button size="lg" discreet variant="danger" onClick={onDelete}>
                <FormattedMessage id="Hedger.ScenariosModal.EditScenario.Delete" />
              </Button>
              <BorderItem />
            </ShowIf>
            <Button size="lg" onClick={onCancel}>
              <FormattedMessage id="Hedger.ScenariosModal.CreateFrame.Cancel" />
            </Button>
            <Button size="lg" discreet variant="info" onClick={onSaveClick}>
              <FormattedMessage
                id={
                  mode === 'edit'
                    ? 'Hedger.ScenariosModal.EditFrame.Save'
                    : 'Hedger.ScenariosModal.CreateFrame.Create'
                }
              />
            </Button>
          </div>
        </div>

        <div className="d-flex flex-wrap from-group-container flex-wrap column-gap-5">
          <div className="form-group-deprecated">
            <label htmlFor={getScenarioInputId('name')} className="form-label">
              <FormattedMessage id="Hedger.Scenario.Name" />
            </label>
            <input
              className="form-control form-control"
              value={state.scenarioData.name}
              onChange={e => stateMethods.onScenarioNameChange(e.target.value)}
              type="text"
              id={getScenarioInputId('name')}
            />
          </div>

          <div className="form-group-deprecated ">
            <label htmlFor={getScenarioInputId('team')} className="form-label">
              Owner team
            </label>
            <Select<string>
              id={getScenarioInputId('team')}
              itemsAsObjects={teams}
              selectedItem={state.scenarioData.team}
              onChange={stateMethods.onTeamChange}
            />
          </div>
          <div className="form-group-deprecated">
            <label htmlFor={getScenarioInputId('ordersMetricsCurrency')} className="form-label">
              Currency
            </label>
            <Select<CurrencyType>
              id={getScenarioInputId('ordersMetricsCurrency')}
              itemsAsObjects={Currencies}
              selectedItemValue={state.scenarioData.ordersMetricsCurrency}
              onChange={stateMethods.onCurrencyChange}
            />
          </div>

          <MultiselectDropdown<DeactivatedValuesType>
            selectedItems={state.scenarioData.deactivatedProductTypes}
            itemsToFreeze={['UNKNOWN', 'INDEX', 'BASKET']}
            items={DeactivatedValues}
            onChange={stateMethods.onProductTypeChange}
            label="Deactivated product type"
          />

          <div className="form-group-deprecated">
            <label htmlFor={getScenarioInputId('hedgeAxis')} className="form-label">
              Axis
            </label>
            <Select<HedgeAxis>
              itemToString={formatHedgeAxis}
              id={getScenarioInputId('hedgeAxis')}
              itemsAsObjects={HedgeAxes}
              selectedItemValue={state.scenarioData.hedgeAxis}
              onChange={stateMethods.onHedgeAxisChange}
            />
          </div>
        </div>
        <EditAxisGrid
          hedgeAxis={state.scenarioData.hedgeAxis}
          currency={state.scenarioData.ordersMetricsCurrency}
          axisConfig={state.axisConfig}
          recipients={recipients}
          markets={markets}
        />
      </div>
    </div>
  );
}

function getScenarioInputId(key: keyof Scenario): string {
  return `createScenario_${key}`;
}
