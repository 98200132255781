import {
  DEFAULT_PERIMETER_PRESET_KEY,
  type PerimeterPreset,
} from '@/store/slices/prefs/perimeterPresetsSlice.ts';
import {
  allSaveViewChoices,
  onlyNewSaveViewChoice,
} from '@/components/equityRisk/controlPanel/saveViewChoices.ts';
import { isPerimeterKeyEqualOrReduced } from '@/components/gridTable/tools/reducePerimeterKeys.ts';

export function getSaveViewChoices(
  activePerimeterPreset: PerimeterPreset | undefined,
  allPerimetersAllowed: string[],
) {
  if (
    activePerimeterPreset === undefined ||
    activePerimeterPreset.team === DEFAULT_PERIMETER_PRESET_KEY.team
  ) {
    return onlyNewSaveViewChoice;
  }
  const areAllPerimetersAllowed = activePerimeterPreset.perimeters.every(activePerimeter =>
    allPerimetersAllowed.some(allowedPerimeter =>
      isPerimeterKeyEqualOrReduced(activePerimeter, allowedPerimeter),
    ),
  );

  return areAllPerimetersAllowed ? allSaveViewChoices : onlyNewSaveViewChoice;
}
