import { assertUnreachable } from '@sgme/fp';

import type { Hierarchy } from '@/core/hierachies.ts';
import { getMeasureById, getMeasureType, type MeasureId } from '@/core/measures.ts';
import { getMeasureMdxQueryPart } from '@/core/query/mdx/clause/mdxMeasures.ts';
import type {
  FilterMeasure,
  MeasureFilters,
  QueryFilter,
} from '@/core/query/mdx/filter/filterModel.ts';
import type { CubeMode } from '@/types/AppConfig.ts';
import { strictEntries } from '@/utils/libs/entries.ts';

export function buildFilterStatement(
  filterHierarchy: Hierarchy,
  cubeMode: CubeMode,
  measureFilters: MeasureFilters,
): string {
  const dimension = `[${filterHierarchy}].Children`;
  const predicate = buildAllPredicate(filterHierarchy, measureFilters, cubeMode);
  return `FILTER(${dimension}, ${predicate})`;
}

function buildAllPredicate(
  filterHierarchy: Hierarchy,
  measureFilters: MeasureFilters,
  cubeMode: CubeMode,
): string {
  const allPredicates = [];
  for (const [measureId, filters] of strictEntries(measureFilters)) {
    allPredicates.push(
      `( ${filters
        .map(f => `${buildPredicate(filterHierarchy, measureId, f, cubeMode)}`)
        .join(' AND ')} )`,
    );
  }
  return allPredicates.join(' AND ');
}

function buildPredicate(
  filterHierarchy: Hierarchy,
  measure: FilterMeasure,
  filter: QueryFilter,
  cubeMode: CubeMode,
): string {
  if (filter.type === 'number' && measure === 'Hierarchy') {
    throw new Error('Cannot do number filter on hierarchy column');
  }

  const col =
    measure === 'Hierarchy'
      ? `[${filterHierarchy}].[${filterHierarchy}].currentmember.Properties('Member_Caption')`
      : getTupleMeasure(measure, cubeMode);

  if (filter.type === 'string') {
    const operator = filter.operator;
    switch (operator) {
      case 'contains':
        return `instr(${col}, "${filter.value}") > 0`;
      case 'notContains':
        return `instr(${col}, "${filter.value}") = 0`;
      case 'startsWith':
        return `instr(${col}, "${filter.value}") = 1`;
      case 'equals':
        return `${col} = "${filter.value}"`;
      case 'notEqual':
        return `${col} <> "${filter.value}"`;
      case 'blank':
        return `ISEMPTY(${col})`;
      case 'notBlank':
        return `NOT(ISEMPTY(${col}))`;
      case 'inList':
        return filter.values.map(value => `${col} = "${value}"`).join(' OR ');
      default:
        assertUnreachable(operator, 'Filter type not handled!');
    }
  } else {
    // number
    const operator = filter.operator;
    const coeff = getCoefficientForMeasure(measure);
    switch (operator) {
      case 'equals':
        return `${col} = ${filter.value * coeff}`;
      case 'between':
        return `${col} >= ${filter.from * coeff} AND ${col} <= ${filter.to * coeff}`;
      case 'notBetween':
        return `${col} < ${filter.from * coeff} OR ${col} > ${filter.to * coeff}`;
      case 'notEqual':
        return `${col} <> ${filter.value * coeff}`;
      case 'greaterThanOrEqual':
        return `${col} >= ${filter.value * coeff}`;
      case 'greaterThan':
        return `${col} > ${filter.value * coeff}`;
      case 'lessThan':
        return `${col} < ${filter.value * coeff}`;
      case 'lessThanOrEqual':
        return `${col} <= ${filter.value * coeff}`;
      default:
        assertUnreachable(operator, 'Filter type not handled!');
    }
  }
}

function getCoefficientForMeasure(measure: FilterMeasure): number {
  if (measure === 'Hierarchy') {
    throw new Error('Cannot get coefficient for Hierarchy');
  }
  const measureType = getMeasureType(measure);
  switch (measureType) {
    case 'percentage':
      return 1 / 100;
    case 'currency':
      return 1000;
    case 'quantity':
    case 'price':
      return 1;
    case 'text':
      throw new Error('Cannot get coefficient for text measure');
    default:
      assertUnreachable(measureType, 'Unhandled measure type');
  }
}

function getTupleMeasure(measureId: MeasureId, cubeMode: 'sgCube' | 'greatServer') {
  const measure = getMeasureById(measureId);
  return getMeasureMdxQueryPart(measure, cubeMode);
}
