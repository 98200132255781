import { useState, type ChangeEvent } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { nanoid } from 'nanoid';

import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { saveWidgetConfigToSessionServiceThunk } from '@/store/slices/widget/widgetsSlice.ts';
import {
  WidgetCategories,
  type WidgetCategory,
  type WidgetDef,
  type WidgetType,
} from '@/store/slices/widget/widgetTypes.ts';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { CloseFooter } from '@/components/common/bootstrap/CloseFooter.tsx';
import { useInterval } from '@/components/common/hooks/useInterval.ts';
import {
  AllWidgets,
  WidgetsByCategory,
  WidgetsByType,
  type WidgetParams,
} from '@/components/equityRisk/widgets/allWidgets.ts';
import {
  selectSelectedWidgetDefs,
  selectWidgetConfigFromWidget,
} from '@/components/equityRisk/widgets/widgetSelectors.ts';

interface Notification {
  expiry: number;
  label: string;
}

export const ManageWidgetsModal = NiceModal.create(() => {
  const dispatch = useAppDispatch();
  const selectedWidgetDefs = useAppSelector(selectSelectedWidgetDefs);

  const [activeWidgetCategory, setActiveWidgetCategory] = useState<WidgetCategory>('General');
  const [currentNotification, setCurrentNotification] = useState<Notification>();

  function onSelectedWidgetDefs(nextSelectedWidgets: WidgetDef<any>[]) {
    dispatch(
      saveWidgetConfigToSessionServiceThunk(nextSelectedWidgets.map(selectWidgetConfigFromWidget)),
    );
  }

  return (
    <BootstrapModal
      style={{ height: '85em', minWidth: '80em' }}
      titleId="Widget.Manage.Title"
      footer={
        <CloseFooter>
          <Notifications
            currentNotification={currentNotification}
            setCurrentNotification={setCurrentNotification}
          />
        </CloseFooter>
      }
    >
      <div className="row h-100">
        <WidgetCategoriesPanel
          activeCategory={activeWidgetCategory}
          onCategoryClick={setActiveWidgetCategory}
        />
        <WidgetList
          activeWidgetCategory={activeWidgetCategory}
          initiallySelectedWidgetDefs={selectedWidgetDefs}
          onSelectedWidgetsEdit={onSelectedWidgetDefs}
          setCurrentNotification={setCurrentNotification}
        />
      </div>
    </BootstrapModal>
  );
});

function WidgetCategoriesPanel({
  onCategoryClick,
  activeCategory,
}: {
  onCategoryClick: (category: WidgetCategory) => void;
  activeCategory: WidgetCategory;
}) {
  return (
    <div className="col-3 list-group border-end">
      {WidgetCategories.map(widgetCategory => (
        <button
          key={widgetCategory}
          onClick={() => onCategoryClick(widgetCategory)}
          className={`d-flex list-group-item list-group-item-action justify-content-between ${
            activeCategory === widgetCategory ? 'active' : ''
          }`}
          type="button"
        >
          {widgetCategory}
          <span className="badge bg-primary rounded-pill">
            {WidgetsByCategory.get(widgetCategory)?.length ?? 0}
          </span>
        </button>
      ))}
    </div>
  );
}

type WidgetListProps = {
  onSelectedWidgetsEdit: (category: WidgetDef<WidgetParams>[]) => void;
  activeWidgetCategory: WidgetCategory;
  initiallySelectedWidgetDefs: WidgetDef<WidgetParams>[];
  setCurrentNotification: (notification: Notification | undefined) => void;
};

function WidgetList(widgetListProps: WidgetListProps) {
  const {
    onSelectedWidgetsEdit,
    activeWidgetCategory,
    initiallySelectedWidgetDefs,
    setCurrentNotification,
  } = widgetListProps;

  const [searchInput, setSearchInput] = useState<string>('');
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const [selectedWidgets, setSelectedWidgets] = useState<WidgetDef<WidgetParams>[]>(
    initiallySelectedWidgetDefs,
  );
  const handleAddClick = (widgetType: WidgetType) => {
    const widget = WidgetsByType.get(widgetType);
    if (widget === undefined) {
      return;
    }

    setCurrentNotification({
      label: 'The widget has been added successfully',
      expiry: new Date().getTime() + 2000,
    });

    const widgetToAdd: WidgetDef<WidgetParams> = {
      ...widget,
      id: widgetType + '_' + nanoid(12),
    };

    const nextSelectedWidgets = [...selectedWidgets, widgetToAdd];
    setSelectedWidgets(nextSelectedWidgets);

    onSelectedWidgetsEdit(nextSelectedWidgets);
  };

  const displayedWidgets = AllWidgets.filter(widget => {
    const includedInSearch = widget.name
      .toLocaleLowerCase()
      .includes(searchInput.toLocaleLowerCase());
    const categoryMatches =
      activeWidgetCategory === 'General' || widget.category === activeWidgetCategory;

    return includedInSearch && categoryMatches;
  });
  return (
    <div className="col-9 d-flex flex-column h-100 ">
      <h5>{activeWidgetCategory}</h5>
      <div className="form-group-deprecated mt-3">
        <div className="input-group">
          <div className="input-icon-start">
            <i className="icon">search</i>
          </div>
          <input
            type="search"
            className="form-control "
            placeholder="Search.."
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="mt-2 overflow-auto">
        {displayedWidgets.map(widget => (
          <WidgetListItem key={widget.type} widget={widget} onAddWidget={handleAddClick} />
        ))}
      </div>
    </div>
  );
}

interface WidgetOverviewProps {
  widget: Omit<WidgetDef<any>, 'id'>;
  onAddWidget: (widgetType: WidgetType) => void;
}

function WidgetListItem({ onAddWidget, widget }: WidgetOverviewProps) {
  const { trackEvent } = useMatomo();
  return (
    <div className={`d-flex card card-bordered  m-2 `}>
      <div className="d-flex">
        <div className="card-body d-flex align-items-center justify-content-between">
          <div>
            <div className="text-xlarge fw-medium">{widget.name}</div>
            <p className="my-1">{widget.description}</p>
            <div className="d-flex py-1 gap-2">
              <Badge label={widget.category} level="primary" />
              <Badge label="Customizable" level="info" />
            </div>
          </div>
        </div>
        <Button
          discreet
          onClick={() => {
            trackEvent({
              category: 'Widgets',
              action: `Add widget`,
              name: widget.name,
            });
            onAddWidget(widget.type);
          }}
        >
          <em className="icon">add</em>
        </Button>
      </div>
    </div>
  );
}

function Badge(props: { label: string; level: 'info' | 'primary' }) {
  return <span className={`badge badge-discreet-${props.level}`}>{props.label}</span>;
}

interface NotificationProps {
  currentNotification: Notification | undefined;
  setCurrentNotification: (notification: Notification | undefined) => void;
}

function Notifications({
  currentNotification,
  setCurrentNotification,
}: NotificationProps): JSX.Element | null {
  useInterval(() => {
    const now = new Date().getTime();
    if (currentNotification !== undefined && currentNotification.expiry < now)
      setCurrentNotification(undefined);
  }, 1000);

  if (currentNotification === undefined) {
    return null;
  }

  return (
    <div
      className="position-absolute w-100 d-flex justify-content-center pointer-events-none "
      style={{ bottom: 40, right: 0, zIndex: 100 }}
    >
      <div className="alert-success p-3" role="alert">
        {currentNotification.label}
      </div>
    </div>
  );
}
