import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type {
  BlotterDeal,
  BookPreTradeDealRequest,
  PostResponse,
  PreTradeDeal,
} from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { getBlotterCriteria } from '@/store/api/adjustmentsApi/adjustmentsApiUtils.ts';
import { setRequestHeaders } from '@/web/utils/webApi.ts';
import { getConfig } from '@/utils/config/config.ts';

export const adjustmentApi = createApi({
  reducerPath: 'adjustmentApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig().apiUrl}/api/xone`,
    // TODO temporary big timeout, fix when X-ONE server is OK
    timeout: 300000,
    prepareHeaders: headers => {
      return setRequestHeaders(headers);
    },
  }),
  endpoints: builder => ({
    getPretradeDeal: builder.query<PreTradeDeal, string>({
      query: tradeReference => tradeReference,
    }),

    loadBlotterDeals: builder.mutation<BlotterDeal[], void>({
      query: () => ({
        url: 'sotw',
        method: 'POST',
        body: getBlotterCriteria(),
      }),
    }),

    bookPreTradeDeal: builder.mutation<PostResponse, BookPreTradeDealRequest>({
      query: body => ({
        url: '',
        method: 'POST',
        body,
      }),
    }),

    editPreTradeDeal: builder.mutation<PostResponse, PreTradeDeal>({
      query: preTradeDeal => ({
        url: `${preTradeDeal.tradeReference}/Modify`,
        method: 'POST',
        body: preTradeDeal,
      }),
    }),

    deletePreTradeDeal: builder.mutation<PostResponse, string>({
      query: tradeReference => ({
        url: `${tradeReference}/Destroy`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useDeletePreTradeDealMutation,
  useLazyGetPretradeDealQuery,
  useBookPreTradeDealMutation,
  useEditPreTradeDealMutation,
} = adjustmentApi;
