import { createSelector } from '@reduxjs/toolkit';

import {
  selectWidgetConfigs,
  widgetConfigSchema,
  type WidgetConfig,
} from '@/store/slices/widget/widgetsSlice.ts';
import { type WidgetDef } from '@/store/slices/widget/widgetTypes.ts';
import { WidgetsByType, type WidgetParams } from '@/components/equityRisk/widgets/allWidgets.ts';
import { objectKeys } from '@/utils/libs/entries.ts';

export function selectWidgetConfigFromWidget(widget: WidgetDef<WidgetParams>): WidgetConfig {
  const widgetConfigParser = widgetConfigSchema.safeParse(widget);
  // Should always be parsable: Removing the unneeded params based on the schema
  if (!widgetConfigParser.success) {
    throw new Error(`Could not parse widget ${widget}`);
  }

  return widgetConfigParser.data;
}
export const selectSelectedWidgetDefs = createSelector(
  [selectWidgetConfigs],
  (widgetConfigs: WidgetConfig[]): WidgetDef<WidgetParams>[] => {
    return widgetConfigs
      .filter(conf => WidgetsByType.has(conf.type))
      .map(getAndUpdateWidgetWithConfig);
  },
);

function getAndUpdateWidgetWithConfig(widgetConfig: WidgetConfig): WidgetDef<WidgetParams> {
  // Deep copy,  widget can have multiple instances for one type
  const { getQuery, ...widgetRest } = WidgetsByType.get(widgetConfig.type)!;
  const clonedWidgetRest = structuredClone(widgetRest);
  const updatedWidget: WidgetDef<WidgetParams> = {
    getQuery,
    ...clonedWidgetRest,
    ...widgetConfig,
    params: clonedWidgetRest.params,
  };
  // Update Hierarchy Column
  const hierarchyUpdated = widgetConfig.params.rowAggregation;
  const hierarchyColumn = updatedWidget.columns?.find(column => column.type === 'hierarchy');
  if (hierarchyUpdated?.value !== undefined && hierarchyColumn !== undefined) {
    hierarchyColumn.name = hierarchyUpdated.value;
  }

  // Update Params
  for (const configParam of objectKeys(widgetConfig.params)) {
    const updatedWidgetParam = updatedWidget.params[configParam];
    const widgetConfigParam = widgetConfig.params[configParam];
    if (updatedWidgetParam === undefined || widgetConfigParam === undefined) {
      continue;
    }
    updatedWidget.params[configParam] = {
      ...updatedWidgetParam,
      ...widgetConfigParam,
    } as any;
  }

  return updatedWidget;
}
