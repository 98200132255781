import { createFileRoute } from '@tanstack/react-router';

import { equityRiskLoader } from '@/components/equityRisk/equityRiskLoader.tsx';
import { WidgetPage } from '@/components/equityRisk/widgets/page/WidgetPage.tsx';

export const Route = createFileRoute('/widgets/')({
  loader: equityRiskLoader,
  component: WidgetPage,
  // No loader cache. https://tanstack.com/router/v1/docs/guide/data-loading#using-shouldreload-and-gctime-to-opt-out-of-caching
  gcTime: 0,
});
