import type { ColDef, ColGroupDef, ITooltipParams } from '@ag-grid-community/core';
import { assertUnreachable } from '@sgme/fp';
import groupBy from 'just-group-by';

import { getCellValueFormatter } from '@/core/format.ts';
import {
  getMeasureById,
  getMeasureType,
  isFromCache,
  type AllMeasureId,
  type Measure,
  type MeasureId,
  type MeasureNameFromCache,
  type MeasureType,
} from '@/core/measures.ts';
import type { RowData } from '@/core/parsing/parseResponse.ts';
import type {
  NegativeNumberFormat,
  NumberFormat,
} from '@/store/slices/prefs/userPreferencesSlice.ts';
import { absValueComparator } from '@/components/equityRisk/gridMappings/comparators.ts';
import { getColorRulesCellClassRules } from '@/components/equityRisk/gridMappings/getColorRulesCellClassRules.ts';
import { InnerHierarchyRenderer } from '@/components/equityRisk/gridMappings/InnerHierarchyRenderer.tsx';
import type { MeasureColumn } from '@/components/equityRisk/gridMappings/measureColumns.ts';
import type { CubeMode } from '@/types/AppConfig.ts';

export interface FetchContext {
  getCubeMode: () => CubeMode;
  getMeasureKey: (measure: Measure, cubeMode: CubeMode) => string;
  getFromCache: (measureId: MeasureNameFromCache, dataPath: string[]) => any;
  getLabel: (measureId: AllMeasureId) => string;
  numberFormat: NumberFormat;
  negativeNumberFormat: NegativeNumberFormat;
  flashCell: boolean;
}

export function getColDefs(
  measureColumns: MeasureColumn[],
  context: FetchContext,
): (ColDef<RowData> | ColGroupDef<RowData>)[] {
  const measuresByParent = groupBy(measureColumns, columnMapping => columnMapping.parent ?? '');
  const getRootMeasureColumns = (): MeasureColumn[] => measuresByParent[''] ?? [];

  return getRootMeasureColumns().map(measureColumn => {
    const childMeasures = measuresByParent[measureColumn.measureId];

    if (childMeasures === undefined) {
      return getColDef(measureColumn, context);
    }
    return getGroupColDef(measureColumn, context, childMeasures);
  });
}

function getColDef(measureColumn: MeasureColumn, context: FetchContext): ColDef<RowData> {
  const { measureId } = measureColumn;

  const measureType = getMeasureType(measureId);
  const tooltip = measureColumn.tooltip;
  const tooltipValueGetter =
    tooltip !== undefined
      ? (params: ITooltipParams<RowData>) => tooltip(params, context)
      : undefined;
  return {
    colId: measureId,
    headerName: context.getLabel(measureId),
    valueGetter: params => getValueForMeasure(params.data, measureColumn.measureId, context),
    valueFormatter: getCellValueFormatter(
      context.numberFormat,
      context.negativeNumberFormat,
      measureType,
    ),
    enableCellChangeFlash: context.flashCell,
    cellClassRules: getColorRulesCellClassRules(measureColumn, context),
    cellClass: measureType === 'quantity' ? ['fst-italic', 'ag-right-aligned-cell'] : undefined,
    type: 'rightAligned',
    tooltipValueGetter: tooltipValueGetter,
    comparator: getComparator(measureType),
  };
}

function getComparator(
  measureType: MeasureType,
): ((valueA: any, valueB: any) => number) | undefined {
  switch (measureType) {
    case 'percentage':
    case 'currency':
    case 'price':
    case 'quantity':
      return absValueComparator;
    case 'text':
      // rely on default comparator
      return undefined;
    default:
      assertUnreachable(measureType, '');
  }
}

function getGroupColDef(
  measureColumn: MeasureColumn,
  context: FetchContext,
  childMeasures: MeasureColumn[],
): ColGroupDef<RowData> {
  const colDef = getColDef(measureColumn, context);
  const children: ColDef<RowData>[] = [
    {
      ...colDef,
      initialWidth: 120,
      type: 'rightAligned',
    },
    ...childMeasures?.map(child => {
      return {
        ...getColDef(child, context),
        columnGroupShow: child.sticky === true ? undefined : ('open' as const),
        headerClass: 'ag-right-aligned-header',
      };
    }),
  ];
  return {
    headerName: colDef.headerName,
    groupId: colDef.colId + '_group',
    marryChildren: true,

    children,
  };
}

export function getHierarchyColDef(context: FetchContext): ColDef<RowData> {
  return {
    enableCellChangeFlash: context.flashCell,
    headerName: 'Hierarchy',
    colId: 'Hierarchy',
    initialWidth: 200,
    initialPinned: 'left',
    // filter: 'agTextColumnFilter',
    // cellClassRules: getClassRulesOfHierarchy(context),
    valueGetter: params => {
      const rowData = params.data;
      return rowData?.dataPath.at(-1);
    },

    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      innerRenderer: InnerHierarchyRenderer,
    },
  };
}

export function makeMeasureKeyGetter(): (measure: Measure, cubeMode: CubeMode) => string {
  return function (measure, cubeMode) {
    const measureName = getMeasureName(measure, cubeMode);
    const valuationType = measure.valuationType !== 'All' ? `/${measure.valuationType}` : '';
    const dailyOrOpen = measure.dailyOrOpen !== 'All' ? `/${measure.dailyOrOpen}` : '';

    return `${measureName}/AllMember${valuationType}/AllMember${dailyOrOpen}`;
  };
}

export function getMeasureName(measure: Measure, cubeMode: CubeMode): string {
  return cubeMode === 'sgCube' && measure.aggregation !== undefined
    ? `${measure.name.replaceAll('.', '')}.${measure.aggregation}`
    : `${measure.name}`;
}

export function getValueForMeasure(
  data: RowData | undefined,
  measureId: MeasureId | MeasureNameFromCache,
  context: FetchContext,
) {
  if (isFromCache(measureId)) {
    return context.getFromCache(measureId, data?.dataPath ?? []);
  }
  const measure = getMeasureById(measureId);
  const measureKey = context.getMeasureKey(measure, context.getCubeMode());
  if (measureKey === undefined) {
    return undefined;
  }
  return data === undefined ? undefined : data[measureKey];
}
