import type { ChangeEvent } from 'react';
import type { CustomCellRendererProps } from '@ag-grid-community/react';

import { Checkbox } from '@/components/common/bootstrap/Checkbox.tsx';
import type { TableMode } from '@/components/rules/RulesCategoryComponent.tsx';

export function CustomCheckboxWithLabel(
  props: CustomCellRendererProps & { mode?: TableMode; labelOnTrue: string; labelOnFalse: string },
) {
  const field = props.colDef?.field;
  const value = props.value;
  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    if (field === undefined || props.mode === 'view') {
      return;
    }
    const updatedValue = event.target.checked;
    props.node.setDataValue(field, updatedValue);
  }

  return (
    <Checkbox checked={props.value} onChange={handleCheckboxChange}>
      <span className={props.value ? 'text-success' : 'text-danger'}>
        {value ? props.labelOnTrue : props.labelOnFalse}
      </span>
    </Checkbox>
  );
}
