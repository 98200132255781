import omit from 'just-omit';
import { asSequence } from 'sequency';

import type {
  RmmColumNames,
  RuleCategory,
  RuleGridRow,
  RulesModel,
} from '@/store/api/rulesApi/rulesModels.ts';
import type { AnalyticalStructure } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { isRuleLineVisibleGetter } from '@/components/rules/isRuleLineVisibleGetter.ts';
import {
  BaseRowDataManager,
  type RuleRowData,
} from '@/components/rules/rowDataManager/BaseRowDataManager.ts';

export class RowDataManager extends BaseRowDataManager {
  protected initialRowData: Map<string, RuleRowData> = new Map<string, RuleRowData>();

  constructor(
    category: RuleCategory,
    rules: RulesModel[],
    analyticalStructures: AnalyticalStructure[],
  ) {
    super(category, rules, analyticalStructures);

    this.calculateRowDatas();
  }

  reset(updatedRules?: RulesModel[]) {
    this.rules = updatedRules ?? this.rules;

    this.calculateRowDatas();

    this.ruleIdByMetric = asSequence(this.rules)
      .map(r => ({ ruleId: r.id, metric: r.metric }))
      .associateBy(r => r.metric);

    this.duplicatedEntries = new Map();
  }

  importRowData(rowDataToAdd: RuleRowData[]) {
    const rowDataToAddMap = new Map(
      rowDataToAdd.map(rowToAdd => [this.getKey(rowToAdd), { rowToAdd, toRemove: false }]),
    );

    this.rowData.forEach(row => {
      const rowKeyBalboa = this.getKey(row);
      const element = rowDataToAddMap.get(rowKeyBalboa);
      if (element !== undefined) {
        this.updateRowWithImportedRow(row, element.rowToAdd);
        element.toRemove = true;
      }
    });

    rowDataToAddMap.forEach(element => {
      if (!element.toRemove) {
        this.rowData.push(element.rowToAdd);
      }
    });
  }

  // !! GridModel contains also unallowedRowData ( rows filtered out depending on analytical structures ie perimeter permissions )
  toGridModel(): RulesModel[] {
    const rowDataByRuleId = asSequence(this.rowData.concat(this.unallowedRowData)).groupBy(r => {
      // filters out rows without metric!
      return this.ruleIdByMetric.get(r.metric)?.ruleId;
    });
    return this.rules.map(rule => {
      const ruleRowData = rowDataByRuleId.get(rule.id) ?? [];
      const newGrid: RuleGridRow[] = ruleRowData.map(rowData => {
        return {
          // new  rows have a negative id initiated in frontend ( mainly for aggrid purpose).
          // we have to remove id property because server accept only undefined ID for newly created row
          id: (rowData.id > 0 ? rowData.id : undefined) as number,
          line: this.toLine(rowData),
        };
      });

      return {
        ...rule,
        grid: newGrid,
      };
    });
  }

  hasValueChanged(rowId: string | undefined, colKey: string, value: any): boolean {
    if (rowId === undefined) {
      return false;
    }

    const initialRow = this.initialRowData.get(rowId);
    if (initialRow === undefined) {
      return true;
    }

    const initialValue = this.getValue(initialRow, colKey);
    return initialValue !== value;
  }

  private calculateRowDatas() {
    this.rowData = [];
    this.unallowedRowData = [];

    const rowDatas = this.toRowDatas(this.rules);
    for (const rowData of rowDatas) {
      if (isRuleLineVisibleGetter(this.analyticalStructures)(rowData)) {
        this.rowData.push(rowData);
      } else {
        this.unallowedRowData.push(rowData);
      }
    }
    this.initialRowData = asSequence(this.rowData)
      // shallow clone
      .map(r => ({ ...r }))
      .associateBy(r => r.id.toString());
  }

  private updateRowWithImportedRow(row: RuleRowData, importedRow: RuleRowData) {
    const unwantedColumns: string[] = ['id', 'topLevelRuleId', ...this.keyColumns];
    const columnsToCopy = Object.keys(row).filter(key => !unwantedColumns.includes(key));

    columnsToCopy.forEach(key => {
      // @ts-ignore
      row[key] = importedRow[key];
    });
  }

  private toLine(rowData: RuleRowData): RmmColumNames {
    return {
      ...omit(rowData, 'topLevelRuleId', 'metric', 'id'),
    };
  }

  private toRowDatas(rules: RulesModel[]): RuleRowData[] {
    return rules.flatMap(rule => {
      return rule.grid.map(grid => {
        const rowData: RuleRowData = {
          metric: rule.metric,
          topLevelRuleId: rule.id,
          // newly created row have
          id: grid.id,
          ...grid.line,
        };
        return rowData;
      });
    });
  }
}
