export function parseBooleanOrBooleanLike(value: string | boolean): boolean {
  if (typeof value === 'boolean') {
    return value;
  }

  if (value.toLocaleLowerCase() === 'yes' || value.toLocaleLowerCase() === 'true') {
    return true;
  } else if (
    value.toLocaleLowerCase() === 'no' ||
    value.toLocaleLowerCase() === 'false' ||
    value.trim() === ''
  ) {
    return false;
  }
  throw new Error(`${value} is not a correct boolean`);
}
export function parseFloatValue(value: string): number {
  const result = parseFloat(value);
  if (isNaN(result)) {
    throw new Error(`${value} is not a correct number`);
  }

  return result;
}
