import { useEffect } from 'react';
import { useLoaderData } from '@tanstack/react-router';

import { useAppSelector } from '@/store/hooks.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { PauseButton } from '@/components/equityRisk/controlPanel/PauseButton.tsx';
import { PerimeterSelector } from '@/components/equityRisk/controlPanel/perimeter/PerimeterSelector.tsx';
import { AddWidgetButton } from '@/components/equityRisk/controlPanel/widgets/AddWidgetButton.tsx';
import { WidgetPanel } from '@/components/equityRisk/widgets/WidgetPanel.tsx';
import { LiveUpdatesBanner } from '@/components/gridTable/LiveUpdatesBanner.tsx';

export function WidgetPage() {
  const { webSocketConnection } = useLoaderData({ from: '/widgets/' });
  const perimeterKeys = useAppSelector(queryCacheSlice.selectors.perimeterKeys);

  useEffect(() => {
    return () => {
      webSocketConnection.close();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="px-4 pt-2">
      <div className="d-flex flex-between">
        <div className="d-flex gap-2">
          <PerimeterSelector allPerimeters={perimeterKeys} />
          <PauseButton />
        </div>
        <AddWidgetButton />
      </div>

      <LiveUpdatesBanner />
      <WidgetPanel webSocketConnection={webSocketConnection} fullscreen />
    </div>
  );
}
