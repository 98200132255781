import type { CubeMode } from '../../../../scripts/AppConfig';

import type { Hierarchy } from '@/core/hierachies.ts';
import type { MeasureId } from '@/core/measures.ts';
import { getExclusionClause } from '@/core/query/mdx/clause/getExclusionClause.ts';
import type { ExclusionMode } from '@/store/slices/query/querySlice.ts';
import { getMeasureWidgetName } from '@/components/equityRisk/widgets/allWidgets.ts';

export function getWidgetSlicerClause(valueDate: string, exclusionMode: ExclusionMode): string {
  return `WHERE {
  (
        [ValueDate].[${valueDate}],
        [ValuationType].[ValuationType].[AllMember].[Intraday],
        [RiskEpoch].[LAST],
        ${getExclusionClause(exclusionMode)}
    ) }
  `;
}
interface MdxQueryClauses {
  column: string;
  row: string;
  from: string;
  slicer: string;
}
export function getMdxQueryTemplateForWidget(queryClauses: MdxQueryClauses): string {
  const { column, from, row, slicer } = queryClauses;
  return `
      SELECT {${column} }
      ON COLUMNS,
          ${row}
          ON ROWS
      FROM ( ${from})
          ${slicer}
  `;
}
export function getWidgetRowClause(
  measureId: MeasureId,
  hierarchy: Hierarchy | undefined,
  numberOfLines: number | undefined,
  cubeMode: CubeMode,
) {
  const measureName = getMeasureWidgetName(measureId, cubeMode);
  const formattedHierarchy = `[${hierarchy}].[${hierarchy}].[${hierarchy}]`;

  return `TopCount
            (${formattedHierarchy}, ${numberOfLines}, [Measures].[${measureName}])`;
}

export function getWidgetColumnClause(measuresIds: MeasureId[], cubeMode: CubeMode) {
  return measuresIds
    .map(measureId => `[Measures].[${getMeasureWidgetName(measureId, cubeMode)}]`)
    .join(`, `);
}
export function getWidgetMultiAxisColumnClause(
  hierarchy: Hierarchy | undefined,
  measureId: MeasureId | undefined,
  cubeMode: CubeMode,
) {
  const measureName = measureId !== undefined ? getMeasureWidgetName(measureId, cubeMode) : '';
  return ` TopCount([${hierarchy}].[${hierarchy}].[${hierarchy}],10,[Measures].[Measures].[${measureName}]) * [Measures].[Measures].[${measureName}]`;
}

export function getFromClause(cubeName: string, perimeters: string[]): string {
  const perimetersQueryPart = perimeters
    .map(p => `[AnalyticalStructure].[AnalyticalStructure].${p}`)
    .join(',');

  return `SELECT
          { ${perimetersQueryPart} } ON COLUMNS
          FROM [${cubeName}]`;
}
