import {
  type GridApi,
  type IServerSideDatasource,
  type IServerSideGetRowsParams,
} from '@ag-grid-community/core';

import type { MdxColumn } from '@/core/parsing/parseResponse.ts';
import { makeQueryId } from '@/core/webSocket/queryId.ts';
import { type WebSocketConnection } from '@/core/webSocket/types.ts';
import { userPreferencesSlice } from '@/store/slices/prefs/userPreferencesSlice.ts';
import type { AppStore } from '@/store/store.ts';

export class WidgetDataSource implements IServerSideDatasource {
  private readonly queryId: string;

  constructor(
    private webSocketConnection: WebSocketConnection,
    private gridApi: GridApi,
    private query: string,
    id: string,
    private store: AppStore,
    private onColumnsReady?: (columns: MdxColumn[]) => void,
  ) {
    this.queryId = makeQueryId('Widget_' + id);
  }

  destroy(): void {
    this.webSocketConnection.cancelQueries('widget', id => id === this.queryId);
  }

  async getRows(params: IServerSideGetRowsParams) {
    const state = this.store.getState();
    const liveUpdates = state.draftQuery.liveUpdates;
    const contextValues = userPreferencesSlice.selectors.contextValues(state);

    const iterator = this.webSocketConnection.queryGenerator({
      queryId: this.queryId,
      queryGroup: 'widget',
      mdx: this.query,
      contextValues: { ...contextValues, 'mdx.hiddengrandtotals': '1' },
      initialState: liveUpdates ? 'STARTED' : 'PAUSED',
    });

    let version = 0;
    for await (const rowUpdate of iterator) {
      if (++version === 1) {
        if (rowUpdate.columns !== undefined && this.onColumnsReady !== undefined) {
          this.onColumnsReady(rowUpdate.columns);
        }
        params.success({ rowData: rowUpdate.add, rowCount: rowUpdate.add.length });
      } else {
        this.gridApi.applyServerSideTransactionAsync(rowUpdate);
      }
    }
  }

  public refreshQuery(query: string) {
    this.query = query;
    const cancelledNumber = this.webSocketConnection.cancelQueries(
      'widget',
      id => id === this.queryId,
    );
    if (cancelledNumber > 0) {
      this.gridApi.refreshServerSide({ route: [], purge: true });
    }
  }
}
