import { useState } from 'react';
import { getRouteApi } from '@tanstack/react-router';
import { asSequence } from 'sequency';

import {
  allHierarchies,
  hierarchiesDataOnly,
  type Hierarchy,
  type HierarchyDataOnly,
} from '@/core/hierachies.ts';
import type { RowData } from '@/core/parsing/parseResponse.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { CopyButton } from '@/components/common/components/CopyButton.tsx';
import { SelectableGrid } from '@/components/gridTable/tools/SelectableGrid.tsx';
import { ExecView } from '@/components/queryTools/components/ExecView.tsx';
import { QueryResultTable } from '@/components/queryTools/components/QueryResultTable.tsx';

type ExportRowData = RowData & Record<Hierarchy, unknown>;
type AllHierarchies = HierarchyDataOnly | Hierarchy;

const excludedHierarchies: AllHierarchies[] = [
  'ProductCode',
  'DealId',
  'ClpId',
  'DefId',
  'ResetId',
];
const hierarchies: AllHierarchies[] = [
  ...allHierarchies.filter(h => !excludedHierarchies.includes(h)),
  ...hierarchiesDataOnly,
];
const payload = JSON.stringify({
  data: {
    context: { 'mdx.hiddensubtotals': hierarchies.map(h => `[${h}].[${h}].[${h}]`).join(',') },
  },
});

function makeQuery(selectedPerimeters: string[], cubeName: string) {
  const currentQuery = `SELECT
  NON EMPTY Crossjoin(
    ${hierarchies
      .map(h => `Hierarchize( Descendants( {[${h}].[${h}].[${h}]}, 1, SELF_AND_BEFORE ) )`)
      .join(',')}
  ) ON ROWS,
  NON EMPTY{[Measures].[contributors.COUNT]}ON COLUMNS
FROM
  (
    SELECT
{${selectedPerimeters.join(',')}} ON COLUMNS
    FROM
      [${cubeName}]
  )`;
  return currentQuery;
}

const routeApi = getRouteApi('/export');

export function ExportToolsPage(): JSX.Element {
  const { websocket } = routeApi.useLoaderData();

  const [selectedPerimeters, setSelectedPerimeters] = useState<string[]>([]);
  const cubeName = useAppSelector(state => state.query.cubeName);
  const currentQuery = makeQuery(selectedPerimeters, cubeName);

  const analyticalStructureRowData = useAppSelector(
    queryCacheSlice.selectors.analyticalStructureRowData,
  );

  return (
    <div key={websocket.id} className="d-flex h-100">
      <div className="d-flex flex-grow-1 p-2 gap-2" style={{ minWidth: 400, maxWidth: 400 }}>
        <SelectableGrid
          rowData={analyticalStructureRowData}
          selectedKeys={selectedPerimeters}
          onSelectKeys={setSelectedPerimeters}
        />
      </div>
      <ExecView
        renderResult={result => {
          return (
            <div className="d-flex flex-grow-1">
              <QueryResultTable treeData={false} data={result} />
              <ExportViewer rowData={result.rowData as any} />
            </div>
          );
        }}
        currentQuery={currentQuery}
        queryPayload={payload}
      />
    </div>
  );
}

function toCsCode(rowData: ExportRowData): string {
  return `UnderlyerData<Hierarchy>.Create(
        ${hierarchies.map(h => `(Hierarchy.${h}, "${rowData[h]}")`).join(',\n\t')}
)`;
}

function ExportViewer(props: { rowData: ExportRowData[] }) {
  const groupedByPC = asSequence(props.rowData).groupBy(
    r => '' + r['ProfitCenter'] + r['GOP'] + r['PortFolio'],
  );
  const reasonableDataVolume = [...groupedByPC.entries()].flatMap(([k, rows]) => {
    // keep only 100 results by AnalyticalKey
    return rows.slice(0, 100);
  });
  const text = reasonableDataVolume.map(toCsCode).join(',\n');
  return (
    <div className="d-flex flex-column">
      <CopyButton text={text} />
      <h2>{reasonableDataVolume.length} data</h2>
      <pre style={{ minWidth: 300, overflowY: 'scroll' }}>{text}</pre>
    </div>
  );
}
