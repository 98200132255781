import { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { useLazyGetPretradeDealQuery } from '@/store/api/adjustmentsApi/adjustmentApi.ts';
import type { PreTradeDeal, TradeWay } from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { useAppDispatch } from '@/store/hooks.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { ToggleButton } from '@/components/common/bootstrap/ToggleButton.tsx';
import { logger } from '@/utils/libs/logger.ts';

interface AddNewAdjustmentModalProps {
  tradeReference: string;
}

const wayValue = ['Buy', 'Sell'] as const;

export const EditAdjustmentModal = NiceModal.create<AddNewAdjustmentModalProps>(
  ({ tradeReference }) => {
    const [getPreTradeDeal, { isLoading }] = useLazyGetPretradeDealQuery();
    //Todo improve this
    const [preTradeDeal, setPreTradeDeal] = useState<PreTradeDeal | undefined>(undefined);
    const modal = useModal();
    const dispatch = useAppDispatch();

    useEffect(() => {
      getPreTradeDeal(tradeReference)
        .unwrap()
        .then(setPreTradeDeal)
        .catch(e => {
          logger.logError(
            `Fail to update deal with id ${tradeReference} {error_s}`,
            JSON.stringify(e),
          );
          dispatch(addErrorToastThunk(`Error trying to get deal : ${tradeReference}`));
          modal.hide();
        });
      // eslint-disable-next-line
    }, []);

    function onConfirm() {
      modal.resolve(preTradeDeal);
    }

    function updateUnderlying(value: string) {
      setPreTradeDeal(prevDeal => ({
        ...prevDeal!,
        productInfo: {
          ...prevDeal!.productInfo,
          securityCompleteCode: {
            ...prevDeal!.productInfo.securityCompleteCode,
            code: value,
          },
        },
      }));
    }

    function updateWay(value: TradeWay) {
      setPreTradeDeal(prevDeal => {
        return {
          ...prevDeal!,
          tradeInfo: {
            ...prevDeal!.tradeInfo,
            tradeWay: value,
          },
        };
      });
    }

    function updatePortfolio(value: string) {
      setPreTradeDeal(prevDeal => ({
        ...prevDeal!,
        tradeInfo: {
          ...prevDeal!.tradeInfo,
          portfolio: {
            name: value,
          },
        },
      }));
    }

    function updatePrice(value: number) {
      setPreTradeDeal(prevDeal => ({
        ...prevDeal!,
        productInfo: {
          ...prevDeal!.productInfo,
          productPrice: Number(value),
        },
      }));
    }

    function updateQuantity(value: number) {
      setPreTradeDeal(prevDeal => ({
        ...prevDeal!,
        productInfo: {
          ...prevDeal!.productInfo,
          productQuantity: Number(value),
        },
      }));
    }

    return (
      <BootstrapModal
        titleId={'Adjustment.EditModalTitle'}
        footer={
          <CancelConfirmFooter
            onConfirm={onConfirm}
            confirmButtonProps={{
              component: 'Add',
            }}
          />
        }
      >
        <>
          {isLoading !== true && preTradeDeal !== undefined ? (
            <>
              <div className="mb-3">
                <label htmlFor="Underlying" className="text-secondary form-label">
                  Underlying
                </label>
                <input
                  onChange={e => updateUnderlying(e.target.value)}
                  className="form-control"
                  type="text"
                  value={preTradeDeal?.productInfo.securityCompleteCode.code}
                />
              </div>
              <div className="mb-3">
                <ToggleButton<TradeWay>
                  formatId="Adjustment.Way.Radio.Label"
                  activeValue={preTradeDeal.tradeInfo.tradeWay ?? 'Buy'}
                  values={wayValue}
                  onClick={value => updateWay(value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="Portfolio" className="text-secondary form-label">
                  Portfolio
                </label>
                <input
                  onChange={e => updatePortfolio(e.target.value)}
                  className="form-control"
                  type="text"
                  value={preTradeDeal.tradeInfo.portfolio.name}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="Price" className="text-secondary form-label">
                  Price
                </label>
                <div>
                  <input
                    onChange={e => updatePrice(Number(e.target.value))}
                    className="form-control"
                    type="number"
                    value={preTradeDeal.productInfo.productPrice}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="Price" className="text-secondary form-label">
                  Quantity
                </label>
                <div>
                  <input
                    onChange={e => updateQuantity(Number(e.target.value))}
                    className="form-control"
                    type="number"
                    value={preTradeDeal.productInfo.productQuantity}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="Amount" className="text-secondary form-label">
                  Amount
                </label>
                <input
                  className="form-control"
                  readOnly
                  type="text"
                  value={preTradeDeal.productInfo.amount}
                />
              </div>
            </>
          ) : (
            <Loader />
          )}
        </>
      </BootstrapModal>
    );
  },
);
