import { useEffect, useState } from 'react';
import { Link, useRouter } from '@tanstack/react-router';
import ky from 'ky';
import { FormattedMessage } from 'react-intl';
import { lazily } from 'react-lazily';

import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { selectCurrentInstance, uiSlice } from '@/store/slices/ui/uiSlice.ts';
import { selectUserHasAnyPermission } from '@/store/slices/user/userSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { MessageTooltip } from '@/components/common/bootstrap/MessageTooltip.tsx';
import { Tooltip } from '@/components/common/bootstrap/Tooltip.tsx';
import { IfAnyPermission } from '@/components/common/components/IfAnyPermission.tsx';
import { HelpRequestButton } from '@/components/common/help-request/HelpRequestButton.tsx';
import { DevActions } from '@/components/common/layout/DevActions.tsx';
import { QueryPanelButton } from '@/components/common/layout/QueryPanel.tsx';
import { AccountCenter } from '@/components/common/sgwt/AccountCenter.tsx';
import { ShowIf } from '@/components/common/utils/ShowIf.tsx';
import { HedgeExecutionDropdownButton } from '@/components/hedger/execution/HedgeExecutionDropdownButton.tsx';
import { getOrderGroupIdFromSessionStorage } from '@/components/hedger/getOrderGroupIdFromSessionStorage.ts';
import type { ApplicationEnvironment } from '@/types/AppConfig.ts';
import { getConfig } from '@/utils/config/config.ts';
import { isProdEnv } from '@/utils/config/configUtils.ts';

const { FakeAccountCenter } = lazily(
  () => import('@/components/common/sgwt/FakeAccountCenter.tsx'),
);

function QueryToolButton(props: { onClick: () => void }) {
  return (
    <Button
      flat
      size="lg"
      variant="primary"
      accessKey="q"
      className="btn-icon-start text-nowrap"
      onClick={props.onClick}
    >
      <em className="icon icon-md">bolt</em>
      Query tools
    </Button>
  );
}

function HedgerLink() {
  const orderGroupId = getOrderGroupIdFromSessionStorage();

  if (orderGroupId === undefined) {
    return (
      <Link
        to={'/hedger'}
        className="navbar-link"
        accessKey="x"
        activeProps={{ className: 'active' }}
      >
        <FormattedMessage id="Link.Hedger" />
      </Link>
    );
  }
  const params = { orderGroupId: orderGroupId.toString() };
  return (
    <Link
      to="/hedger/order/$orderGroupId"
      className="navbar-link"
      accessKey="x"
      activeProps={{ className: 'active' }}
      params={params}
    >
      <FormattedMessage id="Link.Hedger" />
    </Link>
  );
}

function VersionInfo() {
  async function getVersion() {
    return await ky
      .get('/version.json')
      .json<object>()
      .catch(() => ({ version: 'dev' }));
  }

  const [version, setVersion] = useState<object>();
  useEffect(() => {
    getVersion().then(setVersion);
  }, []);

  return <div className="multi-line">{JSON.stringify(version, null, 2)}</div>;
}

export function Header() {
  const { cubeHostName, webSocketUrl } = useAppSelector(selectCurrentInstance);
  const router = useRouter();

  const userCanSeeDevTools = useAppSelector(selectUserHasAnyPermission('seeDevTools'));
  const canAccessStorm = useAppSelector(selectUserHasAnyPermission('useHedgerSourceStorm'));
  const isGreatAvailable = useAppSelector(queryCacheSlice.selectors.isStateInitialized);

  const shouldDisplayDropDown = canAccessStorm || isGreatAvailable;

  return (
    <nav className="navbar border-bottom">
      <EnvironmentBadge wsUrl={webSocketUrl} cubeName={cubeHostName} />

      <Tooltip delay={2000} contents={<VersionInfo />}>
        <div className="navbar-title">
          <a
            className="navbar-title-link cursor-pointer"
            onClick={() => router.navigate({ to: '/' })}
          >
            <div className="navbar-logo">
              <img src="/sg_logo_glyph.svg" alt="SG logo Glyph" />
            </div>
            <div className="navbar-title-divider"></div>
            <div className="navbar-service-name">Equity&nbsp;Risk Analysis</div>
          </a>
        </div>
      </Tooltip>

      <div className="navbar-content">
        <ul className="navbar-navigation m-0">
          <li className="navbar-item">
            <IfAnyPermission permissions={['viewEquityRisk', 'devci']}>
              <Link
                to="/"
                className="navbar-link"
                accessKey="h"
                activeProps={{ className: 'active' }}
              >
                <FormattedMessage id="Link.EquityRisk" />
              </Link>
            </IfAnyPermission>
          </li>
          <li className="navbar-item">
            <IfAnyPermission permissions={['viewHedger', 'devci']}>
              <HedgerLink />
            </IfAnyPermission>
          </li>
          <li className="navbar-item">
            <IfAnyPermission permissions={['viewRules', 'devci']}>
              <Link
                to="/rules"
                className="navbar-link"
                accessKey="r"
                activeProps={{ className: 'active' }}
              >
                <FormattedMessage id="Link.Rules" />
              </Link>
            </IfAnyPermission>
          </li>
          <li className="navbar-item">
            <IfAnyPermission permissions={['viewAdjustments', 'devci']}>
              <Link
                to="/adjustments"
                className="navbar-link"
                accessKey="r"
                activeProps={{ className: 'active' }}
              >
                <FormattedMessage id="Link.Adjustments" />
              </Link>
            </IfAnyPermission>
          </li>
        </ul>
      </div>
      <div className="navbar-toolbar">
        {import.meta.env.DEV ? <DevActions /> : null}
        {userCanSeeDevTools ? <QueryPanelButton /> : null}
        {userCanSeeDevTools ? (
          <QueryToolButton onClick={() => router.navigate({ to: '/queryTools' })} />
        ) : null}

        <IfAnyPermission permissions={['viewHedger']}>
          <ShowIf condition={shouldDisplayDropDown}>
            <HedgeExecutionDropdownButton />
          </ShowIf>
        </IfAnyPermission>

        <HelpRequestButton />
        <UserPrefsButton />

        {import.meta.env.VITE_USE_SG_CONNECT !== 'false' ? (
          <AccountCenter />
        ) : (
          <FakeAccountCenter />
        )}
      </div>
    </nav>
  );
}

function Arrows() {
  return (
    <i className="d-inline-block" style={{ height: 18, width: 18 }}>
      <svg aria-hidden="true" viewBox="0 0 24 24" className="icon" fill="currentColor">
        <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"></path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </i>
  );
}

interface EnvironmentBadgeProps {
  wsUrl: string | undefined;
  cubeName: string | undefined;
}

function EnvironmentBadge({ wsUrl, cubeName }: EnvironmentBadgeProps) {
  if (isProdEnv()) {
    return null;
  }
  const environment = getConfig().environment;
  return (
    <div
      className="position-fixed w-100 d-flex flex-center"
      style={{
        top: '0',
        minWidth: 300,
      }}
    >
      <span
        className="badge"
        style={{
          backgroundColor: getBadgeColor(environment),
          color: 'white',
        }}
      >
        <Arrows />
        <MessageTooltip messageId="Env.Tooltip" messageValues={{ wsUrl }}>
          <span className="cursor-help">
            {environment} on {cubeName}
          </span>
        </MessageTooltip>
        {/*{wsEnvToString(props.wsUrlConfig)}*/}
        <Arrows />
      </span>
    </div>
  );
}

function getBadgeColor(environment: ApplicationEnvironment): string {
  switch (environment) {
    case 'local':
      return 'hotpink';
    case 'devci':
      return '#38699f';
    case 'uat':
      return '#9f389c';
    case 'prod':
      return '#d0034f';
  }
}

function UserPrefsButton() {
  const dispatch = useAppDispatch();
  return (
    <MessageTooltip messageId="UserPrefs.Title">
      <Button
        accessKey="s"
        flat
        icon
        variant="primary"
        size="lg"
        className="ms-1"
        onClick={() => dispatch(uiSlice.actions.toggleCurrentPageOverlay('UserPrefs'))}
      >
        <em className="icon">settings</em>
      </Button>
    </MessageTooltip>
  );
}
